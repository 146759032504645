import { useContext } from 'react';

import { AppContext } from '../app.context';
import { useStyles } from '../services';

export function ThankYouPage() {
    const app = useContext(AppContext);
    

    const styles = useStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: '50px',
        },
        pageTitle: {
            textAlign: 'center',
            fontSize: '48px',
            color: app.theme.palette.primary.main,
        },
        pageSubtitle: {
            textAlign: 'center',
            fontSize: '30px',
        },
    })();

    return <div className={styles.root}>
        <div className={styles.pageTitle}>
            
            <span>Obrigado =D</span>
        </div>
    </div>;
}