import { initializeApp } from "firebase/app";

import {
  getStorage,
  ref,
  uploadBytes,
  deleteObject
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBW1bWtInbqzXRwnsExl606lilRt7N7JKM",
  authDomain: "minha-case.firebaseapp.com",
  projectId: "minha-case",
  storageBucket: "minha-case.appspot.com",
  messagingSenderId: "356937251772",
  appId: "1:356937251772:web:f5f2d608eb3542485b49b9"
};

const ErrorMessages = {
  CredentialsFailed: 'auth/wrong-password',
  EmailExists: 'auth/email-already-in-use',
  InvalidEmail: 'auth/invalid-email',
  UserNotFound: 'auth/user-not-found',
  PopupClosed: 'auth/popup-closed-by-user',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const uploadFileToServer = async (name: string, file: Blob) => {
  const storage = getStorage(app);
  const storageRef = ref(storage, name);
  return await uploadBytes(storageRef, file);
};

const deleteFileFromServer = async (name: string) => {
  const storage = getStorage(app);
  const storageRef = ref(storage, name);
  return await deleteObject(storageRef);
};


export {
  uploadFileToServer,
  deleteFileFromServer,
};