import { Button } from '@mui/material';
import { green } from '@mui/material/colors';
import { useContext } from 'react';
import { AppContext } from '../app.context';
import { useStyles } from '../services';

interface ProductItemCardProps {
  product: any,
  onSelect: () => void,
}

export function ProductItemRow(props: ProductItemCardProps) {
  const app = useContext(AppContext);

  const { product } = props;

  const styles = useStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: '30px',
    },
    thumb: {
      height: '160px',
      // flexGrow: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      flexGrow: 50,
    },
    name: {
      fontSize: '18px',
    },
    price: {
      color: green[500]
    },
    btnWrapper: {
      // flexGrow: 20,
    },
  })();

  return (
    <div className={styles.root}>
      <div className={styles.thumb}>
        {
          !product.images || product.images.length <= 0 ?
            null
            :
            <img src={product.images[0].src} alt='' height='100%' />
        }
      </div>
      <div className={styles.info}>
        <div className={styles.name}>
          <Button variant='text' onClick={() => props.onSelect()} style={{ padding: '0px' }}>{product.name}</Button>
        </div>
        <div className={styles.price}>R$ {product.price}</div>
      </div>
      <div className={styles.btnWrapper}>
        <Button variant='contained' style={{ height: 'fit-content' }} onClick={() => props.onSelect()}>
          Comprar
        </Button>
      </div>
    </div>
  );
}
