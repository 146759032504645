import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../app.context';
import { showStepper, useStyles } from '../services';
import { StepBaseProps } from '.';
import { Button, Grid, Radio, Stack, TextField } from '@mui/material';
import { step2Complete } from '../models';
import { phonesConfig, useCommonStyles } from '../contants';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

interface StepProps extends StepBaseProps {

}

export function Step2Modelo(props: StepProps) {
  const app = useContext(AppContext);
  const { product } = props;

  const small = app.xxs || app.xs;

  const [selected, setSelected] = useState(product.model || '');
  const [search, setSearch] = useState('');
  const [models, setModels] = useState<{ name: string, value: string }[]>([]);

  useEffect(() => {
    const config = phonesConfig.find(x => x.brand === product.brand);
    if (config) setModels(config.models);
  }, []);

  const styles = useStyles({
    root: {
    },
    modelsWrapper: {
      overflow: 'auto',
      height: '385px',
    },
    btnsWrapper: {
      display: 'flex',
      justifyContent: 'space-between'
    },
  })();
  const commonStyles = useCommonStyles();

  return (
    <div className={styles.root}>
      <Stack spacing={4}>
        <Grid container spacing={4} justifyContent='center'>
          <Grid item xs={11} sm={11} md={4}>
            <TextField label="Procurar modelo" variant="standard"
              style={{ width: '100%' }}
              value={search}
              onChange={(e) => setSearch(e.target.value || '')}
            />
          </Grid>
          <Grid item xs={11} sm={11} md={4}>
            <Stack spacing={1} className={`${styles.modelsWrapper} ${commonStyles.customScrollBar}`}>
              {models.filter(x => x.name.toLowerCase().includes(search))
                .map(x => (
                <Button variant={selected === x.value ? 'contained' : 'outlined'}
                  key={x.name}
                  style={{ justifyContent: 'flex-start' }}
                  startIcon={selected === x.value ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
                  onClick={() => {
                    if (selected === x.value) {
                      product.model = '';
                      setSelected('');
                    }
                    else {
                      product.model = x.value;
                      setSelected(x.value);
                    }
                    props.onProductChanged(product);
                  }}
                >
                  
                  {x.name}
                </Button>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <div className={styles.btnsWrapper}>
          <Button disabled={false} variant='contained'
            onClick={() => { if (props.onPreviousStep) props.onPreviousStep(); showStepper(); }}>Anterior</Button>
          <Button disabled={!step2Complete(product)} variant='contained'
            onClick={() => { if (props.onNextStep) props.onNextStep(); showStepper(); }}>Próximo</Button>
        </div>
      </Stack>
    </div>
  );
}
