import { Paper, Modal, Grid, Button, Stack, Select, MenuItem, FormControl, InputLabel, TextField, Tab, Tabs, useMediaQuery } from '@mui/material';
import { KeyboardEvent, useContext, useState } from 'react';
import { decodeJwt } from 'jose';

import { AppContext } from '../app.context';
import { phonesConfig } from '../contants';
import { addToCart, useStyles, signIn as signInBackend, signUp as signUpBackend } from '../services';
import { FormField, useForm } from './form';
import json2mq from 'json2mq';

interface ModalProductDetailProps {
  open: boolean,
  onClose: () => void,
}

interface SignInForm {
  email: string,
  password: string,
}

interface SignUpForm {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
}

export function ModalSignIn(props: ModalProductDetailProps) {
  const app = useContext(AppContext);

  const [tab, setTab] = useState(0);

  const signInForm = useForm<SignInForm>({} as any, {
    email: { label: 'E-mail', type: 'text', required: true },
    password: { label: 'Password', type: 'password', required: true },
  });
  const signUpForm = useForm<SignUpForm>({} as any, {
    email: { label: 'E-mail', type: 'text', required: true },
    password: { label: 'Password', type: 'password', required: true },
    firstName: { label: 'Nome', type: 'text', required: true },
    lastName: { label: 'Sobrenome', type: 'text', required: true },
  });

  const styles = useStyles({
    root: (data: any) => data.small ?
      {
        height: '100%',
        padding: '30px 20px',
      }
      :
      {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        padding: '30px 50px'
      },
    img: {
      height: '400px',
    },
  })({
    small: app.xxs || app.xs
  });

  const signInKey = (ev: KeyboardEvent<Element>) => {
    if (signInForm.isValid() && ev.key === "Enter") {
      signIn(signInForm.values.email, signInForm.values.password);
    }
  };
  const signUpKey = (ev: KeyboardEvent<Element>) => {
    if (signUpForm.isValid() && ev.key === "Enter") { signUp(); }
  };
  const signIn = async (email: string, pwd: string) => {
    try {
      app.setLoading(true);
      const response = await signInBackend(email, pwd) as any;
      app.setUser({
        id: (decodeJwt(response.token).data as any).user!.id,
        name: response['user_display_name'],
        email
      })
      props.onClose();
    }
    catch (error) {
      console.error(error);
      app.notification('error', 'Algo deu errado. Contate o administrator.');
    }
    app.setLoading(false);
  };
  const signUp = async () => {
    try {
      app.setLoading(true);
      await signUpBackend(
        signUpForm.values.firstName!,
        signUpForm.values.lastName!,
        signUpForm.values.email!,
        signUpForm.values.password!
      );
      await signIn(signUpForm.values.email!, signUpForm.values.password!);
    }
    catch (error) {
      console.error(error);
      app.notification('error', 'Algo deu errado. Contate o administrator.');
    }
    app.setLoading(false);
  };

  const isSignIn = () => tab === 0;

  return <Modal open={props.open} onClose={() => props.onClose()}>
    <Paper className={styles.root} elevation={6}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '22px' }}>
        <Tabs value={tab} onChange={(e, v) => setTab(v)}>
          <Tab label="Login" />
          <Tab label="Registrar" />
        </Tabs>
        {
          isSignIn() ?
            <>
              <FormField form={signInForm} field='email' onKeyPressed={signInKey} />
              <FormField form={signInForm} field='password' onKeyPressed={signInKey} />
            </>
            :
            <Grid container spacing={3} width='100%' sx={{ flexGrow: 1 }}>
              <Grid item xs={12} sm={6}>
                <FormField form={signUpForm} field='firstName' onKeyPressed={signUpKey} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField form={signUpForm} field='lastName' onKeyPressed={signUpKey} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField form={signUpForm} field='email' onKeyPressed={signUpKey} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField form={signUpForm} field='password' onKeyPressed={signUpKey} />
              </Grid>
            </Grid>
        }
        <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '10px' }}>
          {
            isSignIn() ?
              <Button variant='contained' disabled={!signInForm.isValid()}
                onClick={() => signIn(signInForm.values.email!, signInForm.values.password!)}
              >Login</Button>
              :
              <Button variant='contained' onClick={signUp} disabled={!signUpForm.isValid()}>Registrar</Button>
          }
          <Button variant='outlined' onClick={() => props.onClose()}>Cancelar</Button>
        </div>
      </div>
    </Paper>
  </Modal>;
}
