import { useContext, useEffect, useState } from "react";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    ArrowForwardIos as LeftIcon,
    ArrowBackIos as RightIcon,
} from '@mui/icons-material';
import { AppContext } from "../app.context";

import { Footer, Header, Loading, Notification, WhatsApp } from "../components";
import { Routes } from "../contants";
import { getParameterByName, useNavigation, useStyles } from "../services";
import { IconButton } from "@mui/material";

export function DefaultLayout() {
    const app = useContext(AppContext);
    const { navigate } = useNavigation();
    
    const small = app.xxs || app.xs || app.sm;
  
    const [show, setShow] = useState(false);

    useEffect(() => {
        const redirectFrom = getParameterByName('redirect-from');
        if (redirectFrom) navigate(redirectFrom);
    }, []);

    const styles = useStyles({
        wrapper: {
            
        },
        showBtn: {
            position: 'fixed',
            bottom: '20px',
            right: '0px',
        },
        hideBtn: {
            position: 'fixed',
            bottom: '20px',
            left: '0px',
        },
    })();

    return <div className={app.isDarkTheme() ? 'boh-dark' : ''}
        style={{ minHeight: '100%', }}>
        <Notification />
        {app.loading && <Loading />}
        <Header />
        <div style={{ padding: app.xxs || app.xs || app.sm ? '40px 3%' : '40px 12%', }}>
            <Switch>
                {
                    Object.values(Routes).map(x =>
                        <Route key={x.path}
                            exact={true}
                            path={x.path}
                            component={x.component}></Route>)
                }
                <Redirect to={Routes.Home.path} />
            </Switch>
        </div>
        <Footer />
        {
        small ?
          show ?
            <>
              {<WhatsApp />}
              <IconButton className={styles.hideBtn} onClick={() => setShow(false)}>
                <LeftIcon />
              </IconButton>
            </>
            :
            <IconButton className={styles.showBtn} onClick={() => setShow(true)} color='primary'>
              <RightIcon />
            </IconButton>
          :
          <WhatsApp />
      }
        
    </div>;
}