import { useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { AppContext } from '../app.context';
import { useStyles } from '../services';

export function HomePage() {
    const app = useContext(AppContext);

    const getImgHeight = () => {
        if (app.xl) return '400px';
        else if (app.lg) return '360px';
        else if (app.md) return '300px';
        else if (app.sm) return '260px';
        else if (app.xs) return '190px';
        else if (app.xxs) return '150px';
        return '0px';
    }

    const styles = useStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: '50px',
            margin: (data: any) => data.small ? '-40px -3%' : '-40px -15.8%',
        },
        carousel: {
        },
    })({
        small: app.xxs || app.xs || app.sm
    });

    return (
        <div className={styles.root}>
            <div className={styles.carousel}>
            <Carousel autoPlay={true} swipeable={true} infiniteLoop={true}
                renderThumbs={(c) => []}
                showStatus={false}
            >
                <div>
                    <img height={getImgHeight()}
                        alt='site under construction'
                        src="https://firebasestorage.googleapis.com/v0/b/minha-case.appspot.com/o/static%2Fbanner-1.png?alt=media"
                    />
                </div>
                <div>
                    <img height={getImgHeight()}
                        alt='site under construction'
                        src="https://firebasestorage.googleapis.com/v0/b/minha-case.appspot.com/o/static%2Fbanner-2.jpeg?alt=media" 
                    />
                </div>
                <div>
                    <img height={getImgHeight()}
                        alt='site under construction'
                        src="https://firebasestorage.googleapis.com/v0/b/minha-case.appspot.com/o/static%2Fbanner-3.png?alt=media" 
                    />
                </div>
            </Carousel>
            </div>
        </div>
    );
}