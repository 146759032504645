export const phonesConfig = [
    {
        "brand": "apple",
        "label": "Apple",
        "models": [
            {
                "name": "iPhone 11",
                "value": "iphone_11"
            },
            {
                "name": "iPhone 11 Pro",
                "value": "iphone_11_pro"
            },
            {
                "name": "iPhone 11 Pro Max",
                "value": "iphone_11_pro_max"
            },
            {
                "name": "iPhone 12",
                "value": "iphone_12"
            },
            {
                "name": "iPhone 12 Mini",
                "value": "iphone_12_mini"
            },
            {
                "name": "iPhone 12 Pro",
                "value": "iphone_12_pro"
            },
            {
                "name": "iPhone 12 Pro Max",
                "value": "iphone_12_pro_max"
            },
            {
                "name": "iPhone 13",
                "value": "iphone_13"
            },
            {
                "name": "iPhone 13 Mini",
                "value": "iphone_13_mini"
            },
            {
                "name": "iPhone 13 Pro",
                "value": "iphone_13_pro"
            },
            {
                "name": "iPhone 13 Pro Max",
                "value": "iphone_13_pro_max"
            },
            {
                "name": "iPhone 14",
                "value": "iphone_14"
            },
            {
                "name": "iPhone 14 Max",
                "value": "iphone_14_max"
            },
            {
                "name": "iPhone 14 Pro",
                "value": "iphone_14_pro"
            },
            {
                "name": "iPhone 14 Pro Max",
                "value": "iphone_14_pro_max"
            },
            {
                "name": "iPhone 4/4s",
                "value": "iphone_4_4s"
            },
            {
                "name": "iPhone 5/5s",
                "value": "iphone_5_5s"
            },
            {
                "name": "iPhone 6/6s",
                "value": "iphone_6_6s"
            },
            {
                "name": "iPhone 6/6s (Anti Impacto)",
                "value": "iphone_6_6s_anti_impacto"
            },
            {
                "name": "iPhone 6/6s Plus",
                "value": "iphone_6_6s_plus"
            },
            {
                "name": "iPhone 6/6s Plus (Anti Impacto)",
                "value": "iphone_6_6s_plus_anti_impacto"
            },
            {
                "name": "iPhone 7s Plus (Anti Impacto)",
                "value": "iphone_7s_plus_anti_impacto"
            },
            {
                "name": "iPhone 7/8 (Anti Impacto)",
                "value": "iphone_7_8_anti_impacto"
            },
            {
                "name": "iPhone 8 Plus (Anti Impacto)",
                "value": "iphone_8_plus_anti_impacto"
            },
            {
                "name": "iPhone SE (2020) (Anti Impacto)",
                "value": "iphone_se_2020_anti_impacto"
            },
            {
                "name": "iPhone X",
                "value": "iphone_x"
            },
            {
                "name": "iPhone XR",
                "value": "iphone_xr"
            },
            {
                "name": "iPhone XS",
                "value": "iphone_xs"
            },
            {
                "name": "iPhone XS Max",
                "value": "iphone_xs_max"
            }
        ]
    },
    {
        "brand": "asus",
        "label": "Asus",
        "models": [
            {
                "name": "Zenfone 2 Laser (5.0)",
                "value": "zenfone_2_laser_5.0"
            },
            {
                "name": "Zenfone 3 (5.2)",
                "value": "zenfone_3_5.2"
            },
            {
                "name": "Zenfone 3 Deluxe (5.7)",
                "value": "zenfone_3_deluxe_5.7"
            },
            {
                "name": "Zenfone 3 Max (5.2)",
                "value": "zenfone_3_max_5.2"
            },
            {
                "name": "Zenfone 3 Max (5.5)",
                "value": "zenfone_3_max_5.5"
            },
            {
                "name": "Zenfone 4 Max (5.5)",
                "value": "zenfone_4_max_5.5"
            },
            {
                "name": "Zenfone 4 Selfie Pro (5.5)",
                "value": "zenfone_4_selfie_pro_5.5"
            },
            {
                "name": "Zenfone 5 Selfie/Pro (2018)",
                "value": "zenfone_5_selfie_pro_2018"
            },
            {
                "name": "Zenfone 6",
                "value": "zenfone_6"
            },
            {
                "name": "Zenfone 6 (ZS630KL)",
                "value": "zenfone_6_zs630kl"
            },
            {
                "name": "Zenfone Live",
                "value": "zenfone_live"
            },
            {
                "name": "Zenfone Live L1 (ZA550KL)",
                "value": "zenfone_live_l1_za550kl"
            },
            {
                "name": "Zenfone Max M2 (ZB555KL)",
                "value": "zenfone_max_m2_zb555kl"
            },
            {
                "name": "Zenfone Max Plus M1",
                "value": "zenfone_max_plus_m1"
            },
            {
                "name": "Zenfone Max Plus M2",
                "value": "zenfone_max_plus_m2"
            },
            {
                "name": "Zenfone Max Pro M1 (ZB602KL)",
                "value": "zenfone_max_pro_m1_zb602kl"
            },
            {
                "name": "Zenfone Max Pro M2 (ZB631KL)",
                "value": "zenfone_max_pro_m2_zb631kl"
            },
            {
                "name": "Zenfone Max Shot",
                "value": "zenfone_max_shot"
            }
        ]
    },
    {
        "brand": "huawei",
        "label": "Huawei",
        "models": [
            {
                "name": "P30",
                "value": "p30"
            },
            {
                "name": "P30 Pro",
                "value": "p30_pro"
            }
        ]
    },
    {
        "brand": "lenovo",
        "label": "Lenovo",
        "models": [
            {
                "name": "Vibe K6",
                "value": "vibe_k6"
            }
        ]
    },
    {
        "brand": "lg",
        "label": "LG",
        "models": [
            {
                "name": "G2 Lite",
                "value": "g2_lite"
            },
            {
                "name": "G2 Mini D625",
                "value": "g2_mini_d625"
            },
            {
                "name": "G2 Mini Dual D618",
                "value": "g2_mini_dual_d618"
            },
            {
                "name": "G3 Beat D724",
                "value": "g3_beat_d724"
            },
            {
                "name": "G3 D855",
                "value": "g3_d855"
            },
            {
                "name": "G3 Mini/G3s",
                "value": "g3_mini_g3s"
            },
            {
                "name": "G3 Stylus",
                "value": "g3_stylus"
            },
            {
                "name": "G3 Beat H736",
                "value": "g4_beat_h736"
            },
            {
                "name": "G4 H815P",
                "value": "g4_h815p"
            },
            {
                "name": "G Flex D956",
                "value": "g_flex_d956"
            },
            {
                "name": "G Pro E989",
                "value": "g_pro_e989"
            },
            {
                "name": "K10",
                "value": "k10"
            },
            {
                "name": "K10 (2017)",
                "value": "k10_2017"
            },
            {
                "name": "K10 (2018)",
                "value": "k10_2018"
            },
            {
                "name": "K10 Power",
                "value": "k10_power"
            },
            {
                "name": "K10 Pro",
                "value": "k10_pro"
            },
            {
                "name": "K11/K11+",
                "value": "k11_k11+"
            },
            {
                "name": "K12+",
                "value": "k12+"
            },
            {
                "name": "K12 Max/K12 Prime",
                "value": "k12_max_k12_prime"
            },
            {
                "name": "K22/K22+",
                "value": "k22_k22+"
            },
            {
                "name": "K40s",
                "value": "k40s"
            },
            {
                "name": "K41s",
                "value": "k41s"
            },
            {
                "name": "K4 (2017)",
                "value": "k4_2017"
            },
            {
                "name": "K50s",
                "value": "k50s"
            },
            {
                "name": "K51s",
                "value": "k51s"
            },
            {
                "name": "K52",
                "value": "k52"
            },
            {
                "name": "K61",
                "value": "k61"
            },
            {
                "name": "K62/K62 Plus",
                "value": "k62_k62_plus"
            },
            {
                "name": "K7",
                "value": "k7"
            },
            {
                "name": "K71",
                "value": "k71"
            },
            {
                "name": "K8+ (2019)",
                "value": "k8+_2019"
            },
            {
                "name": "K8",
                "value": "k8"
            },
            {
                "name": "K8 (2017)",
                "value": "k8_2017"
            },
            {
                "name": "K9/K8 (2018)",
                "value": "k9_k8_2018"
            },
            {
                "name": "L1 Dual E410",
                "value": "l1_dual_e410"
            },
            {
                "name": "L3 E400",
                "value": "l3_e400"
            },
            {
                "name": "L4 Dual TV E467",
                "value": "l4_dual_tv_e467"
            },
            {
                "name": "L2 Dual E615",
                "value": "l5_dual_e615"
            },
            {
                "name": "L5 E612",
                "value": "l5_e612"
            },
            {
                "name": "L70 Dual D325",
                "value": "l70_dual_d325"
            },
            {
                "name": "L7 Dual TV P716",
                "value": "l7_dual_tv_p716"
            },
            {
                "name": "L7 P705",
                "value": "l7_p705"
            },
            {
                "name": "L80 Dual D385",
                "value": "l80_dual_d385"
            },
            {
                "name": "L9 P768",
                "value": "l9_p768"
            },
            {
                "name": "L Prime Dual TV D337",
                "value": "l_prime_dual_tv_d337"
            },
            {
                "name": "Nexus 4",
                "value": "nexus_4"
            },
            {
                "name": "Nexus 5",
                "value": "nexus_5"
            },
            {
                "name": "Optimus G E977",
                "value": "optimus_g_e977"
            },
            {
                "name": "Q7/Q7 Plus",
                "value": "q7_q7_plus"
            },
            {
                "name": "X Cam",
                "value": "x_cam"
            },
            {
                "name": "X Power",
                "value": "x_power"
            },
            {
                "name": "X Screen",
                "value": "x_screen"
            },
            {
                "name": "X Style",
                "value": "x_style"
            }
        ]
    },
    {
        "brand": "microsoft",
        "label": "Microsoft",
        "models": [
            {
                "name": "Lumia 640",
                "value": "lumia_640"
            }
        ]
    },
    {
        "brand": "motorola",
        "label": "Motorola",
        "models": [
            {
                "name": "Moto C",
                "value": "moto_c"
            },
            {
                "name": "Moto C Plus",
                "value": "moto_c_plus"
            },
            {
                "name": "Moto E20",
                "value": "moto_e20"
            },
            {
                "name": "Moto E22",
                "value": "moto_e22"
            },
            {
                "name": "Moto E32",
                "value": "moto_e32"
            },
            {
                "name": "Moto E40",
                "value": "moto_e40"
            },
            {
                "name": "Moto E5 Plus",
                "value": "moto_e5_plus"
            },
            {
                "name": "Moto E6s/E6i",
                "value": "moto_e6s_e6i"
            },
            {
                "name": "Moto E6 Play",
                "value": "moto_e6_play"
            },
            {
                "name": "Moto E6 Plus",
                "value": "moto_e6_plus"
            },
            {
                "name": "Moto E7",
                "value": "moto_e7"
            },
            {
                "name": "Moto E7 Plus",
                "value": "moto_e7_plus"
            },
            {
                "name": "Moto E7 Power",
                "value": "moto_e7_power"
            },
            {
                "name": "Moto Edge+",
                "value": "moto_edge+"
            },
            {
                "name": "Moto Edge",
                "value": "moto_edge"
            },
            {
                "name": "Moto Edge 20",
                "value": "moto_edge_20"
            },
            {
                "name": "Moto Edge 20 Lite",
                "value": "moto_edge_20_lite"
            },
            {
                "name": "Moto Edge 20 Pro",
                "value": "moto_edge_20_pro"
            },
            {
                "name": "Moto Edge 30",
                "value": "moto_edge_30"
            },
            {
                "name": "Moto Edge 30 Fusion",
                "value": "moto_edge_30_fusion"
            },
            {
                "name": "Moto Edge 30 Neo",
                "value": "moto_edge_30_neo"
            },
            {
                "name": "Moto Edge 30 Pro",
                "value": "moto_edge_30_pro"
            },
            {
                "name": "Moto Edge 30 Ultra",
                "value": "moto_edge_30_ultra"
            },
            {
                "name": "Moto E 1ª Geração",
                "value": "moto_e_1a_geracao"
            },
            {
                "name": "Moto E 2ª Geração",
                "value": "moto_e_2a_geracao"
            },
            {
                "name": "Moto E 3ª Geração",
                "value": "moto_e_3a_geracao"
            },
            {
                "name": "Moto E 4ª Geração",
                "value": "moto_e_4a_geracao"
            },
            {
                "name": "Moto E 4 Plus",
                "value": "moto_e_4_plus"
            },
            {
                "name": "Moto E 5ª Geração",
                "value": "moto_e_5a_geracao"
            },
            {
                "name": "Moto G100",
                "value": "moto_g100"
            },
            {
                "name": "Moto G10/G30",
                "value": "moto_g10_g30"
            },
            {
                "name": "Moto G20",
                "value": "moto_g20"
            },
            {
                "name": "Moto G200",
                "value": "moto_g200"
            },
            {
                "name": "Moto G22",
                "value": "moto_g22"
            },
            {
                "name": "Moto G31",
                "value": "moto_g31"
            },
            {
                "name": "Moto G32",
                "value": "moto_g32"
            },
            {
                "name": "Moto G41",
                "value": "moto_g41"
            },
            {
                "name": "Moto G42",
                "value": "moto_g42"
            },
            {
                "name": "Moto G50 4G",
                "value": "moto_g50_4g"
            },
            {
                "name": "Moto G50 5G",
                "value": "moto_g50_5g"
            },
            {
                "name": "Moto G51",
                "value": "moto_g51"
            },
            {
                "name": "Moto G52",
                "value": "moto_g52"
            },
            {
                "name": "Moto G5s",
                "value": "moto_g5s"
            },
            {
                "name": "Moto G5s Plus",
                "value": "moto_g5s_plus"
            },
            {
                "name": "Moto G6",
                "value": "moto_g6"
            },
            {
                "name": "Moto G60",
                "value": "moto_g60"
            },
            {
                "name": "Moto G60s",
                "value": "moto_g60s"
            },
            {
                "name": "Moto G62",
                "value": "moto_g62"
            },
            {
                "name": "Moto G6 Play",
                "value": "moto_g6_play"
            },
            {
                "name": "Moto G6 Plus",
                "value": "moto_g6_plus"
            },
            {
                "name": "Moto G71",
                "value": "moto_g71"
            },
            {
                "name": "Moto G7/G7 Plus",
                "value": "moto_g7_g7_plus"
            },
            {
                "name": "Moto G7 Play",
                "value": "moto_g7_play"
            },
            {
                "name": "Moto G7 Power",
                "value": "moto_g7_power"
            },
            {
                "name": "Moto G8",
                "value": "moto_g8"
            },
            {
                "name": "Moto G82",
                "value": "moto_g82"
            },
            {
                "name": "Moto G8 Play",
                "value": "moto_g8_play"
            },
            {
                "name": "Moto G Plus",
                "value": "moto_g8_plus"
            },
            {
                "name": "Moto G8 Power",
                "value": "moto_g8_power"
            },
            {
                "name": "Moto G8 Power Lite",
                "value": "moto_g8_power_lite"
            },
            {
                "name": "Moto G9 Play",
                "value": "moto_g9_play"
            },
            {
                "name": "Moto G9 Plus",
                "value": "moto_g9_plus"
            },
            {
                "name": "Moto G9 Power",
                "value": "moto_g9_power"
            },
            {
                "name": "Moto G 1ª Geração",
                "value": "moto_g_1a_geracao"
            },
            {
                "name": "Moto G 3ª Geração",
                "value": "moto_g_3a_geracao"
            },
            {
                "name": "Moto G 4ª Geração",
                "value": "moto_g_4a_geracao"
            },
            {
                "name": "Moto G 4a Plus",
                "value": "moto_g_4a_plus"
            },
            {
                "name": "Moto G 5ª Geração",
                "value": "moto_g_5a_geracao"
            },
            {
                "name": "Moto G 5a Plus",
                "value": "moto_g_5a_plus"
            },
            {
                "name": "Moto G 5G",
                "value": "moto_g_5g"
            },
            {
                "name": "Moto G 5G Plus",
                "value": "moto_g_5g_plus"
            },
            {
                "name": "Moto G Stylus",
                "value": "moto_g_stylus"
            },
            {
                "name": "Moto One",
                "value": "moto_one"
            },
            {
                "name": "Moto One Action",
                "value": "moto_one_action"
            },
            {
                "name": "Moto One Fusion",
                "value": "moto_one_fusion"
            },
            {
                "name": "Moto One Fusion Plus",
                "value": "moto_one_fusion_plus"
            },
            {
                "name": "Moto One Vision",
                "value": "moto_one_vision"
            },
            {
                "name": "Moto X 1ª Geração",
                "value": "moto_x_1a_geracao"
            },
            {
                "name": "Moto X 4ª Geração",
                "value": "moto_x_4a_geracao"
            },
            {
                "name": "Moto Z3 Play",
                "value": "moto_z3_play"
            },
            {
                "name": "Moto Z Force",
                "value": "moto_z_force"
            },
            {
                "name": "Nexus 6",
                "value": "nexus_6"
            },
            {
                "name": "One Hyper",
                "value": "one_hyper"
            },
            {
                "name": "One Macro",
                "value": "one_macro"
            },
            {
                "name": "One Zoom",
                "value": "one_zoom"
            },
            {
                "name": "Razr D1",
                "value": "razr_d1"
            },
            {
                "name": "Razr D3",
                "value": "razr_d3"
            }
        ]
    },
    {
        "brand": "nokia",
        "label": "Nokia",
        "models": [
            {
                "name": "N520",
                "value": "n520"
            },
            {
                "name": "N530",
                "value": "n530"
            },
            {
                "name": "N620",
                "value": "n620"
            },
            {
                "name": "N625",
                "value": "n625"
            },
            {
                "name": "N630",
                "value": "n630"
            },
            {
                "name": "N720",
                "value": "n720"
            },
            {
                "name": "N730",
                "value": "n730"
            },
            {
                "name": "N800",
                "value": "n800"
            },
            {
                "name": "N820",
                "value": "n820"
            },
            {
                "name": "N920",
                "value": "n920"
            },
            {
                "name": "N925",
                "value": "n925"
            }
        ]
    },
    {
        "brand": "realme",
        "label": "Realme",
        "models": [
            {
                "name": "7 (6.5)",
                "value": "7_6.5"
            },
            {
                "name": "7 Pro (6.4)",
                "value": "7_pro_6.4"
            },
            {
                "name": "C11",
                "value": "c11"
            }
        ]
    },
    {
        "brand": "samsung",
        "label": "Samsung",
        "models": [
            {
                "name": "Galaxy A01",
                "value": "galaxy_a01"
            },
            {
                "name": "Galaxy A01 Core",
                "value": "galaxy_a01_core"
            },
            {
                "name": "Galaxy A02",
                "value": "galaxy_a02"
            },
            {
                "name": "Galaxy A02s",
                "value": "galaxy_a02s"
            },
            {
                "name": "Galaxy A03",
                "value": "galaxy_a03"
            },
            {
                "name": "Galaxy A03s",
                "value": "galaxy_a03s"
            },
            {
                "name": "Galaxy A03 Core",
                "value": "galaxy_a03_core"
            },
            {
                "name": "Galaxy A04",
                "value": "galaxy_a04"
            },
            {
                "name": "Galaxy A04s",
                "value": "galaxy_a04s"
            },
            {
                "name": "Galaxy A10",
                "value": "galaxy_a10"
            },
            {
                "name": "Galaxy A10s",
                "value": "galaxy_a10s"
            },
            {
                "name": "Galaxy A11",
                "value": "galaxy_a11"
            },
            {
                "name": "Galaxy A12",
                "value": "galaxy_a12"
            },
            {
                "name": "Galaxy A13 4G",
                "value": "galaxy_a13_4g"
            },
            {
                "name": "Galaxy A13 5G",
                "value": "galaxy_a13_5g"
            },
            {
                "name": "Galaxy A14",
                "value": "galaxy_a14"
            },
            {
                "name": "Galaxy A20s",
                "value": "galaxy_a20s"
            },
            {
                "name": "Galaxy A20/A30",
                "value": "galaxy_a20_a30"
            },
            {
                "name": "Galaxy A21s",
                "value": "galaxy_a21s"
            },
            {
                "name": "Galaxy A22 4G (6.4)",
                "value": "galaxy_a22_4g_6.4"
            },
            {
                "name": "Galaxy A22 5G",
                "value": "galaxy_a22_5g"
            },
            {
                "name": "Galaxy A23",
                "value": "galaxy_a23"
            },
            {
                "name": "Galaxy A31",
                "value": "galaxy_a31"
            },
            {
                "name": "Galaxy A32 5G (6.5)",
                "value": "galaxy_a32_5g_6.5"
            },
            {
                "name": "Galaxy A32/A32 4G (6.4)",
                "value": "galaxy_a32_a32_4g_6.4"
            },
            {
                "name": "Galaxy A33 5G",
                "value": "galaxy_a33_5g"
            },
            {
                "name": "Galaxy A34 5G",
                "value": "galaxy_a34_5g"
            },
            {
                "name": "Galaxy A3 (2017)",
                "value": "galaxy_a3_2017"
            },
            {
                "name": "Galaxy A50/A30s",
                "value": "galaxy_a50_a30s"
            },
            {
                "name": "Galaxy A51",
                "value": "galaxy_a51"
            },
            {
                "name": "Galaxy A52",
                "value": "galaxy_a52"
            },
            {
                "name": "Galaxy A52s",
                "value": "galaxy_a52s"
            },
            {
                "name": "Galaxy A53 5G",
                "value": "galaxy_a53_5g"
            },
            {
                "name": "Galaxy A54 5G",
                "value": "galaxy_a54_5g"
            },
            {
                "name": "Galaxy A6 (2018)",
                "value": "galaxy_a6_2018"
            },
            {
                "name": "Galaxy A6 Plus",
                "value": "galaxy_a6_plus"
            },
            {
                "name": "Galaxy A70",
                "value": "galaxy_a70"
            },
            {
                "name": "Galaxy A70s",
                "value": "galaxy_a70s"
            },
            {
                "name": "Galaxy A71",
                "value": "galaxy_a71"
            },
            {
                "name": "Galaxy A72",
                "value": "galaxy_a72"
            },
            {
                "name": "Galaxy A73 5G",
                "value": "galaxy_a73_5g"
            },
            {
                "name": "Galaxy A7 (2017)",
                "value": "galaxy_a7_2017"
            },
            {
                "name": "Galaxy A7 (2018)",
                "value": "galaxy_a7_2018"
            },
            {
                "name": "Galaxy A8 (2018)",
                "value": "galaxy_a8_2018"
            },
            {
                "name": "Galaxy A9 (2018)",
                "value": "galaxy_a9_2018"
            },
            {
                "name": "Galaxy Ace",
                "value": "galaxy_ace"
            },
            {
                "name": "Galaxy Ace 2",
                "value": "galaxy_ace_2"
            },
            {
                "name": "Galaxy Ace 3 Duos",
                "value": "galaxy_ace_3_duos"
            },
            {
                "name": "Galaxy Ace 4 Duos",
                "value": "galaxy_ace_4_duos"
            },
            {
                "name": "Galaxy Ace Duos",
                "value": "galaxy_ace_duos"
            },
            {
                "name": "Galaxy Core 2 Duos",
                "value": "galaxy_core_2_duos"
            },
            {
                "name": "Galaxy Core Plus",
                "value": "galaxy_core_plus"
            },
            {
                "name": "Galaxy Core Prime",
                "value": "galaxy_core_prime"
            },
            {
                "name": "Galaxy E5",
                "value": "galaxy_e5"
            },
            {
                "name": "Galaxy E7",
                "value": "galaxy_e7"
            },
            {
                "name": "Galaxy Express",
                "value": "galaxy_express"
            },
            {
                "name": "Galaxy Fame",
                "value": "galaxy_fame"
            },
            {
                "name": "Galaxy Fame Duos",
                "value": "galaxy_fame_duos"
            },
            {
                "name": "Galaxy Gran 2 Duos TV",
                "value": "galaxy_gran_2_duos_tv"
            },
            {
                "name": "Galaxy Gran Duos",
                "value": "galaxy_gran_duos"
            },
            {
                "name": "Galaxy Gran Neo Duos",
                "value": "galaxy_gran_neo_duos"
            },
            {
                "name": "Galaxy J1 (2016)",
                "value": "galaxy_j1_2016"
            },
            {
                "name": "Galaxy J1 Ace",
                "value": "galaxy_j1_ace"
            },
            {
                "name": "Galaxy J1 Duos",
                "value": "galaxy_j1_duos"
            },
            {
                "name": "Galaxy J1 Mini",
                "value": "galaxy_j1_mini"
            },
            {
                "name": "Galaxy J2 Core",
                "value": "galaxy_j2_core"
            },
            {
                "name": "Galaxy J2 Prime",
                "value": "galaxy_j2_prime"
            },
            {
                "name": "Galaxy J2 Pro",
                "value": "galaxy_j2_pro"
            },
            {
                "name": "Galaxy J3 Pro",
                "value": "galaxy_j3_pro"
            },
            {
                "name": "Galaxy J4 (2018)",
                "value": "galaxy_j4_2018"
            },
            {
                "name": "Galaxy J4 Core",
                "value": "galaxy_j4_core"
            },
            {
                "name": "Galaxy J4 Plus",
                "value": "galaxy_j4_plus"
            },
            {
                "name": "Galaxy J5 (2016)",
                "value": "galaxy_j5_2016"
            },
            {
                "name": "Galaxy J5 (2017)",
                "value": "galaxy_j5_2017"
            },
            {
                "name": "Galaxy J5 Duos",
                "value": "galaxy_j5_duos"
            },
            {
                "name": "Galaxy J5 Metal",
                "value": "galaxy_j5_metal"
            },
            {
                "name": "Galaxy J5 Prime",
                "value": "galaxy_j5_prime"
            },
            {
                "name": "Galaxy J5 Pro",
                "value": "galaxy_j5_pro"
            },
            {
                "name": "Galaxy J6 (2018)",
                "value": "galaxy_j6_2018"
            },
            {
                "name": "Galaxy J6 Plus",
                "value": "galaxy_j6_plus"
            },
            {
                "name": "Galaxy J7 (2016)",
                "value": "galaxy_j7_2016"
            },
            {
                "name": "Galaxy J7 (2017)",
                "value": "galaxy_j7_2017"
            },
            {
                "name": "Galaxy J7 Duos",
                "value": "galaxy_j7_duos"
            },
            {
                "name": "Galaxy J7 Duos (2018)",
                "value": "galaxy_j7_duo_2018"
            },
            {
                "name": "Galaxy J7 Metal",
                "value": "galaxy_j7_metal"
            },
            {
                "name": "Galaxy J7 Neo",
                "value": "galaxy_j7_neo"
            },
            {
                "name": "Galaxy J7 Prime/J7 Prime 2",
                "value": "galaxy_j7_prime_j7_prime_2"
            },
            {
                "name": "Galaxy J7 Pro",
                "value": "galaxy_j7_pro"
            },
            {
                "name": "Galaxy J8 (2018)",
                "value": "galaxy_j8_2018"
            },
            {
                "name": "Galaxy M10",
                "value": "galaxy_m10"
            },
            {
                "name": "Galaxy M11",
                "value": "galaxy_m11"
            },
            {
                "name": "Galaxy M12",
                "value": "galaxy_m12"
            },
            {
                "name": "Galaxy M13",
                "value": "galaxy_m13"
            },
            {
                "name": "Galaxy M22",
                "value": "galaxy_m22"
            },
            {
                "name": "Galaxy M23 5G",
                "value": "galaxy_m23_5g"
            },
            {
                "name": "Galaxy M30",
                "value": "galaxy_m30"
            },
            {
                "name": "Galaxy M31/M21s",
                "value": "galaxy_m31_m21s"
            },
            {
                "name": "Galaxy M32",
                "value": "galaxy_m32"
            },
            {
                "name": "Galaxy M33 5G",
                "value": "galaxy_m33_5g"
            },
            {
                "name": "Galaxy M51",
                "value": "galaxy_m51"
            },
            {
                "name": "Galaxy M52 5G",
                "value": "galaxy_m52_5g"
            },
            {
                "name": "Galaxy M53 5G",
                "value": "galaxy_m53_5g"
            },
            {
                "name": "Galaxy M62",
                "value": "galaxy_m62"
            },
            {
                "name": "Galaxy Note 10+",
                "value": "galaxy_note_10+"
            },
            {
                "name": "Galaxy Note 10",
                "value": "galaxy_note_10"
            },
            {
                "name": "Galaxy Note 10 Lite",
                "value": "galaxy_note_10_lite"
            },
            {
                "name": "Galaxy Note 2",
                "value": "galaxy_note_2"
            },
            {
                "name": "Galaxy Note 20",
                "value": "galaxy_note_20"
            },
            {
                "name": "Galaxy Note 20 Ultra",
                "value": "galaxy_note_20_ultra"
            },
            {
                "name": "Galaxy Note 4",
                "value": "galaxy_note_4"
            },
            {
                "name": "Galaxy Note 8",
                "value": "galaxy_note_8"
            },
            {
                "name": "Galaxy Pocket Neo/Pocket Neo Duos",
                "value": "galaxy_pocket_neo_pocket neo_duos"
            },
            {
                "name": "Galaxy Pocket/Pocket Duos",
                "value": "galaxy_pocket_pocket_duos"
            },
            {
                "name": "Galaxy S10",
                "value": "galaxy_s10"
            },
            {
                "name": "Galaxy S10e",
                "value": "galaxy_s10e"
            },
            {
                "name": "Galaxy S10 Lite",
                "value": "galaxy_s10_lite"
            },
            {
                "name": "Galaxy S10 Plus",
                "value": "galaxy_s10_plus"
            },
            {
                "name": "Galaxy S2",
                "value": "galaxy_s2"
            },
            {
                "name": "Galaxy S20",
                "value": "galaxy_s20"
            },
            {
                "name": "Galaxy S20 FE",
                "value": "galaxy_s20_fe"
            },
            {
                "name": "Galaxy S20 Plus",
                "value": "galaxy_s20_plus"
            },
            {
                "name": "Galaxy S20 Ultra",
                "value": "galaxy_s20_ultra"
            },
            {
                "name": "Galaxy S21",
                "value": "galaxy_s21"
            },
            {
                "name": "Galaxy S21 FE",
                "value": "galaxy_s21_fe"
            },
            {
                "name": "Galaxy S21 Plus",
                "value": "galaxy_s21_plus"
            },
            {
                "name": "Galaxy S21 Ultra",
                "value": "galaxy_s21_ultra"
            },
            {
                "name": "Galaxy S22",
                "value": "galaxy_s22"
            },
            {
                "name": "Galaxy S22 Plus",
                "value": "galaxy_s22_plus"
            },
            {
                "name": "Galaxy S22 Ultra",
                "value": "galaxy_s22_ultra"
            },
            {
                "name": "Galaxy S23",
                "value": "galaxy_s23"
            },
            {
                "name": "Galaxy S23 Plus",
                "value": "galaxy_s23_plus"
            },
            {
                "name": "Galaxy S23 Ultra",
                "value": "galaxy_s23_ultra"
            },
            {
                "name": "Galaxy S2 Duos",
                "value": "galaxy_s2_duos"
            },
            {
                "name": "Galaxy S2 Lite",
                "value": "galaxy_s2_lite"
            },
            {
                "name": "Galaxy S3",
                "value": "galaxy_s3"
            },
            {
                "name": "Galaxy S3 Duos",
                "value": "galaxy_s3_duos"
            },
            {
                "name": "Galaxy S3 Mini",
                "value": "galaxy_s3_mini"
            },
            {
                "name": "Galaxy S3 Neo Duos",
                "value": "galaxy_s3_neo_duos"
            },
            {
                "name": "Galaxy S3 Slim",
                "value": "galaxy_s3_slim"
            },
            {
                "name": "Galaxy S4",
                "value": "galaxy_s4"
            },
            {
                "name": "Galaxy S4 Mini",
                "value": "galaxy_s4_mini"
            },
            {
                "name": "Galaxy S5",
                "value": "galaxy_s5"
            },
            {
                "name": "Galaxy S5 Duos",
                "value": "galaxy_s5_duos"
            },
            {
                "name": "Galaxy S5 Mini",
                "value": "galaxy_s5_mini"
            },
            {
                "name": "Galaxy S5 Neo",
                "value": "galaxy_s5_neo"
            },
            {
                "name": "Galaxy S7 Edge",
                "value": "galaxy_s7_edge"
            },
            {
                "name": "Galaxy S8",
                "value": "galaxy_s8"
            },
            {
                "name": "Galaxy S8 Plus",
                "value": "galaxy_s8_plus"
            },
            {
                "name": "Galaxy S9",
                "value": "galaxy_s9"
            },
            {
                "name": "Galaxy S9 Plus",
                "value": "galaxy_s9_plus"
            },
            {
                "name": "Galaxy S Duos",
                "value": "galaxy_s_duos"
            },
            {
                "name": "Galaxy S Duos 2",
                "value": "galaxy_s_duos_2"
            },
            {
                "name": "Galaxy Trend",
                "value": "galaxy_trend"
            },
            {
                "name": "Galaxy Trend Lite",
                "value": "galaxy_trend_lite"
            },
            {
                "name": "Galaxy Trend Lite Duos",
                "value": "galaxy_trend_lite_duos"
            },
            {
                "name": "Galaxy Win 2 Duos TV",
                "value": "galaxy_win_2_duos_tv"
            },
            {
                "name": "Galaxy Win Duos",
                "value": "galaxy_win_duos"
            },
            {
                "name": "Galaxy Y",
                "value": "galaxy_y"
            },
            {
                "name": "Galaxy Young Duos TV",
                "value": "galaxy_young_duos_tv"
            },
            {
                "name": "Galaxy Y Duos",
                "value": "galaxy_y_duos"
            }
        ]
    },
    {
        "brand": "sony",
        "label": "Sony",
        "models": [
            {
                "name": "Xperia C",
                "value": "xperia_c"
            },
            {
                "name": "Xperia C4",
                "value": "xperia_c4"
            },
            {
                "name": "Xperia E1",
                "value": "xperia_e1"
            },
            {
                "name": "Xperia E3",
                "value": "xperia_e3"
            },
            {
                "name": "Xperia E4",
                "value": "xperia_e4"
            },
            {
                "name": "Xperia E Dual",
                "value": "xperia_e_dual"
            },
            {
                "name": "Xperia J",
                "value": "xperia_j"
            },
            {
                "name": "Xperia L",
                "value": "xperia_l"
            },
            {
                "name": "Xperia L1",
                "value": "xperia_l1"
            },
            {
                "name": "Xperia M2 Aqua",
                "value": "xperia_m2_aqua"
            },
            {
                "name": "Xperia M Dual",
                "value": "xperia_m_dual"
            },
            {
                "name": "Xperia S",
                "value": "xperia_s"
            },
            {
                "name": "Xperia SP",
                "value": "xperia_sp"
            },
            {
                "name": "Xperia T2 Ultra",
                "value": "xperia_t2_ultra"
            },
            {
                "name": "Xperia T2 Ultra Dual",
                "value": "xperia_t2_ultra_dual"
            },
            {
                "name": "Xperia T3",
                "value": "xperia_t3"
            },
            {
                "name": "Xperia Z1",
                "value": "xperia_z1"
            },
            {
                "name": "Xperia Z2",
                "value": "xperia_z2"
            },
            {
                "name": "Xperia Z3",
                "value": "xperia_z3"
            },
            {
                "name": "Xperia Z3 Plus",
                "value": "xperia_z3_plus"
            },
            {
                "name": "Xperia Z4",
                "value": "xperia_z4"
            },
            {
                "name": "Xperia ZQ",
                "value": "xperia_zq"
            },
            {
                "name": "Xperia Z Ultra",
                "value": "xperia_z_ultra"
            }
        ]
    },
    {
        "brand": "xiaomi",
        "label": "Xiaomi",
        "models": [
            {
                "name": "Mi 10T Lite",
                "value": "mi_10t_lite"
            },
            {
                "name": "Mi 10 Lite",
                "value": "mi_10_lite"
            },
            {
                "name": "Mi 10/Mi 10 Pro",
                "value": "mi_10_mi_10_pro"
            },
            {
                "name": "Mi 11",
                "value": "mi_11"
            },
            {
                "name": "Mi 11T",
                "value": "mi_11t"
            },
            {
                "name": "Mi 11 Lite 4G/5g",
                "value": "mi_11_lite_4g_5g"
            },
            {
                "name": "Mi 11 Pro",
                "value": "mi_11_pro"
            },
            {
                "name": "Mi 12",
                "value": "mi_12"
            },
            {
                "name": "Mi 12T Pro",
                "value": "mi_12t_pro"
            },
            {
                "name": "Mi 12 Lite",
                "value": "mi_12_lite"
            },
            {
                "name": "Mi 8 Lite",
                "value": "mi_8_lite"
            },
            {
                "name": "Mi 8/Mi 8 Pro",
                "value": "mi_8_mi_8_pro"
            },
            {
                "name": "Mi 9",
                "value": "mi_9"
            },
            {
                "name": "Mi 9T",
                "value": "mi_9t"
            },
            {
                "name": "Mi 9 Lite",
                "value": "mi_9_lite"
            },
            {
                "name": "Mi 9 SE",
                "value": "mi_9_se"
            },
            {
                "name": "Mi A3",
                "value": "mi_a3"
            },
            {
                "name": "Mi Max 3",
                "value": "mi_max_3"
            },
            {
                "name": "Mi Note 10 Lite",
                "value": "mi_note_10_lite"
            },
            {
                "name": "Mi Note 10/Mi Note 10 Pro",
                "value": "mi_note_10_mi_note_10_pro"
            },
            {
                "name": "Mi Play",
                "value": "mi_play"
            },
            {
                "name": "Pocophone F1",
                "value": "pocophone_f1"
            },
            {
                "name": "Poco F2 Pro",
                "value": "poco_f2_pro"
            },
            {
                "name": "Poco F3",
                "value": "poco_f3"
            },
            {
                "name": "Poco F4 5G",
                "value": "poco_f4_5g"
            },
            {
                "name": "Poco F4 GT",
                "value": "poco_f4_gt"
            },
            {
                "name": "Poco M3",
                "value": "poco_m3"
            },
            {
                "name": "Poco M3 Pro",
                "value": "poco_m3_pro"
            },
            {
                "name": "Poco M4 5G",
                "value": "poco_m4_5g"
            },
            {
                "name": "Poco M4 Pro 4G",
                "value": "poco_m4_pro_4g"
            },
            {
                "name": "Poco M4 Pro 5G",
                "value": "poco_m4_pro_5g"
            },
            {
                "name": "Poco M5",
                "value": "poco_m5"
            },
            {
                "name": "Poco M5s",
                "value": "poco_m5s"
            },
            {
                "name": "Poco X3 GT",
                "value": "poco_x3_gt"
            },
            {
                "name": "Poco X3 Pro",
                "value": "poco_x3_pro"
            },
            {
                "name": "Poco X3/X3 NFC",
                "value": "poco_x3_x3_nfc"
            },
            {
                "name": "Poco X4 GT",
                "value": "poco_x4_gt"
            },
            {
                "name": "Poco X4 Pro 5G",
                "value": "poco_x4_pro_5g"
            },
            {
                "name": "Redmi 10C",
                "value": "redmi_10c"
            },
            {
                "name": "Redmi 10/Redmi 10 Prime",
                "value": "redmi_10_10_prime"
            },
            {
                "name": "Redmi 5 Plus",
                "value": "redmi_5_plus"
            },
            {
                "name": "Redmi 6",
                "value": "redmi_6"
            },
            {
                "name": "Redmi 6A",
                "value": "redmi_6a"
            },
            {
                "name": "Redmi 8",
                "value": "redmi_8"
            },
            {
                "name": "Redmi 8A",
                "value": "redmi_8a"
            },
            {
                "name": "Redmi 9",
                "value": "redmi_9"
            },
            {
                "name": "Redmi 9A",
                "value": "redmi_9a"
            },
            {
                "name": "Redmi 9C",
                "value": "redmi_9c"
            },
            {
                "name": "Redmi 9T",
                "value": "redmi_9t"
            },
            {
                "name": "Redmi 9 Power",
                "value": "redmi_9_power"
            },
            {
                "name": "Redmi A1",
                "value": "redmi_a1"
            },
            {
                "name": "Redmi Note 10s",
                "value": "redmi_note_10s"
            },
            {
                "name": "Redmi Note 10 4G (6.4)",
                "value": "redmi_note_10_4g_6.4"
            },
            {
                "name": "Redmi Note 10 5G",
                "value": "redmi_note_10_5g"
            },
            {
                "name": "Redmi Note 10 Pro",
                "value": "redmi_note_10_pro"
            },
            {
                "name": "Redmi Note 10 Pro Max",
                "value": "redmi_note_10_pro_max"
            },
            {
                "name": "Redmi Note 11/Note 11s",
                "value": "redmi_note_11_note_11s"
            },
            {
                "name": "Redmi Note 11 Pro 5G",
                "value": "redmi_note_11_pro_5g"
            },
            {
                "name": "Redmi Note 11 Pro Plus",
                "value": "redmi_note_11_pro_plus"
            },
            {
                "name": "Redmi Note 5",
                "value": "redmi_note_5"
            },
            {
                "name": "Redmi Note 5A Prime",
                "value": "redmi_note_5a_prime"
            },
            {
                "name": "Redmi Note 6/Note 6 Pro",
                "value": "redmi_note_6_note_6_pro"
            },
            {
                "name": "Redmi Note 7/7 Pro",
                "value": "redmi_note_7_7_pro"
            },
            {
                "name": "Redmi Note 8",
                "value": "redmi_note_8"
            },
            {
                "name": "Redmi Note 8T",
                "value": "redmi_note_8t"
            },
            {
                "name": "Redmi Note 8 Pro",
                "value": "redmi_note_8_pro"
            },
            {
                "name": "Redmi Note 9",
                "value": "redmi_note_9"
            },
            {
                "name": "Redmi Note 9s/Note 9 Pro",
                "value": "redmi_note_9s_note_9_pro"
            },
            {
                "name": "Redmi Note 9T",
                "value": "redmi_note_9t"
            }
        ]
    }
];