export type Category = {
    key: string,
    label: string,
    value: number,
    pickModelBy?: boolean,
};

export const Categories = {
    Apple: { key: 'apple', label: 'Apple', value: 30, pickModelBy: true, } as Category,
    Asus: { key: 'asus', label: 'Asus', value: 49, pickModelBy: true, } as Category,
    Lenovo: { key: 'lenovo', label: 'Lenovo', value: 42, pickModelBy: true, } as Category,
    LG: { key: 'lg', label: 'LG', value: 43, pickModelBy: true, } as Category,
    Microsoft: { key: 'microsoft', label: 'Microsoft', value: 47, pickModelBy: true, } as Category,
    Motorola: { key: 'motorola', label: 'Motorola', value: 32, pickModelBy: true, } as Category,
    Nokia: { key: 'nokia', label: 'Nokia', value: 44, pickModelBy: true, } as Category,
    Realme: { key: 'realme', label: 'Realme', value: 45, pickModelBy: true, } as Category,
    Samsung: { key: 'samsung', label: 'Samsung', value: 24, pickModelBy: true, } as Category,
    
    Sony: { key: 'sony', label: 'Sony', value: 46, pickModelBy: true, } as Category,
    Cabos: { key: 'cabos', label: 'Cabos', value: 36, } as Category,
    Carregador: { key: 'carregador', label: 'Carregador', value: 37, } as Category,
    FoneDeOuvido: { key: 'fone-de-ouvido', label: 'Fone de Ouvido', value: 34, } as Category,
    Pelicula: { key: 'pelicula', label: 'Pelicula', value: 48, } as Category,
    ProtetorDeCabos: { key: 'protetor-de-cabos', label: 'Protetor de cabos', value: 51, } as Category,
    
    Customized: { key: 'customized', label: 'Customized', value: 999, } as Category,
}