import {
  HomePage,
  AcessoriosPage,
  AjudaPage,
  ContatoPage,
  CrieSuaCapinhaPage,
  CapinhasPage,
  CheckoutPage,
  AddressPage,
  ReviewPage,
  ThankYouPage,
  MeusPedidosPage,
  PerfilPage,
} from "../pages";

export enum Route {
  Home = "/",
  Acessorios = "/acessorios",
  Ajuda = "/ajuda",
  Contato = "/contato",
  CrieSuaCapinha = "/crie-sua-capinha",
  Capinhas = "/capinhas",
  Checkout = "/checkout",
  Address = "/address",
  Review = "/review",
  ThankYou = "/thank-you",
  Perfil = "/perfil",
  MeusPedidos = "/meus-pedidos",
}

export const Routes: RoutesModule = {
  Home: { path: Route.Home, component: HomePage },
  Acessorios: { path: Route.Acessorios, component: AcessoriosPage },
  Ajuda: { path: Route.Ajuda, component: AjudaPage },
  Contato: { path: Route.Contato, component: ContatoPage },
  CrieSuaCapinha: { path: Route.CrieSuaCapinha, component: CrieSuaCapinhaPage },
  Capinhas: { path: Route.Capinhas, component: CapinhasPage },
  Checkout: { path: Route.Checkout, component: CheckoutPage },
  Review: { path: Route.Review, component: ReviewPage },
  Address: { path: Route.Address, component: AddressPage },
  ThankYou: { path: Route.ThankYou, component: ThankYouPage },
  Perfil: { path: Route.Perfil, component: PerfilPage },
  MeusPedidos: { path: Route.MeusPedidos, component: MeusPedidosPage },
};

export interface RoutesModule {
  Home: RouteConfig;
  Acessorios: RouteConfig;
  Ajuda: RouteConfig;
  Contato: RouteConfig;
  CrieSuaCapinha: RouteConfig;
  Capinhas: RouteConfig;
  Checkout: RouteConfig;
  Review: RouteConfig;
  Address: RouteConfig;
  ThankYou: RouteConfig;
  Perfil: RouteConfig;
  MeusPedidos: RouteConfig;
}

export interface RouteConfig {
  path: Route;
  component: any;
}
export {};
