import { AppConfig, Categories } from "../contants";
import { getCartItems } from "./cart";
import { httpGet, httpPost } from "./http";

export function contactUs(
  name: string,
  email: string,
  phone: string,
  subject: string,
  msg: string
) {
  return new Promise((res, rej) => {
    var req = new XMLHttpRequest();
    req.open("POST", `${AppConfig.backendUrl}/contact-us`, true);
    req.setRequestHeader(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=utf-8"
    );
    req.onreadystatechange = function () {
      if (this.readyState !== 4) return;
      req.onreadystatechange = null;

      if (this.status >= 200 && this.status <= 204) {
        res(JSON.parse(this.response || "{}") as any);
      } else rej(this.statusText);
    };

    const body = `name-1=${name}&email-1=${email}&phone-1=${phone}&textarea-1=${msg}&text-1=${subject}`;

    req.send(body);
  });
}

export function signUp(
  firstname: string,
  lastname: string,
  email: string,
  pwd: string
) {
  return new Promise((res, rej) => {
    var req = new XMLHttpRequest();
    req.open("POST", `${AppConfig.backendUrl}/sign-up`, true);
    req.setRequestHeader(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=utf-8"
    );
    req.onreadystatechange = function () {
      if (this.readyState !== 4) return;
      req.onreadystatechange = null;

      if (this.status >= 200 && this.status <= 204) {
        res(JSON.parse(this.response || "{}") as any);
      } else rej(this.statusText);
    };

    const body = `firstname=${firstname}&lastname=${lastname}&email=${email}&pwd=${pwd}`;

    req.send(body);
  });
}

export function signIn(email: string, pwd: string) {
  return new Promise((res, rej) => {
    var req = new XMLHttpRequest();
    req.open("POST", `${AppConfig.backendUrl}/sign-in`, true);
    req.setRequestHeader(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=utf-8"
    );
    req.onreadystatechange = function () {
      if (this.readyState !== 4) return;
      req.onreadystatechange = null;

      if (this.status >= 200 && this.status <= 204) {
        res(JSON.parse(this.response || "{}") as any);
      } else rej(this.statusText);
    };

    const body = `email=${email}&pwd=${pwd}`;

    req.send(body);
  });
}

export function getAddressByZip(zip: string) {
  return new Promise((res, rej) => {
    var req = new XMLHttpRequest();
    req.open("GET", `${AppConfig.backendUrl}/address?zip=${zip}`, true);
    req.onreadystatechange = function () {
      if (this.readyState !== 4) return;
      req.onreadystatechange = null;

      if (this.status >= 200 && this.status <= 204) {
        res(JSON.parse(this.response || "{}") as any);
      } else rej(this.statusText);
    };
    req.send();
  });
}
