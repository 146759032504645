import { Step, StepButton, Stepper, } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../app.context';
import { Step1Marca, Step2Modelo, Step3Imagens } from '../components';
import { AppConfig } from '../contants';
import { Product, step1Complete, step2Complete, step3Complete, } from '../models';
import { useStyles } from '../services';


export function CrieSuaCapinhaPage() {
    const app = useContext(AppContext);

    const [activeStep, setActiveStep] = useState(0);
    const [product, setProduct] = useState<Product>({
        name: `Capinha Customizada`,
        type: 'customized',
        price: AppConfig.customizedPrice,
    });
    
    const onNextStep = () => setActiveStep(activeStep + 1);
    const onPrevioustStep = () => setActiveStep(activeStep - 1);
    const onProductChanged = (newProd: any) => setProduct(JSON.parse(JSON.stringify(newProd)));
    
    const steps = [
        {
            label: 'Marca',
            completed: step1Complete(product),
            disabled: false,
            component: <Step1Marca product={product} onProductChanged={onProductChanged} onNextStep={onNextStep} onPreviousStep={onPrevioustStep} />,
        },
        {
            label: 'Modelo',
            completed: step2Complete(product),
            disabled: !step1Complete(product),
            component: <Step2Modelo product={product} onProductChanged={onProductChanged} onNextStep={onNextStep} onPreviousStep={onPrevioustStep} />,
        },
        {
            label: 'Imagens',
            completed: step3Complete(product),
            disabled: !step2Complete(product),
            component: <Step3Imagens product={product} onProductChanged={onProductChanged} onNextStep={onNextStep} onPreviousStep={onPrevioustStep} />,
        },
    ];

    const styles = useStyles({
        root: {
        },
        stepperWrapper: {
            padding: (data: any) => data.small ? '0px' : '30px 80px',
        },
        componentWrapper: {
            margin: (data: any) => data.small ? '30px 0px' : '30px 80px',
        },
    })({
        small: app.xxs || app.xs || app.md
    });

    useEffect(() => {
        function resizeHandler () {
            const root = document.querySelector(`.${styles.root}`) as HTMLDivElement;
            if (root) root.style.minHeight = `${window.innerHeight}px`;
        }
        resizeHandler();
        window.addEventListener("resize", () => resizeHandler);
        return () => window.removeEventListener("resize", resizeHandler);
    }, []);

    return <div className={styles.root}>
        <div className={styles.stepperWrapper} id='mcs-stepper'>
            <Stepper activeStep={activeStep}>
                {steps.map((step, i) => (
                    <Step key={step.label} completed={step.completed} disabled={step.disabled}>
                        <StepButton color="inherit" onClick={() => setActiveStep(i)}>
                        {step.label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </div>
        <div className={styles.componentWrapper}>
            {steps.filter((step, i) => i === activeStep).map(step => step.component)}
        </div>
    </div>;
}