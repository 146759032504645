import { useContext, useEffect, useState } from "react";

import { AppContext } from "../app.context";
import { useStyles, useWordpress } from "../services";
import { Button, CircularProgress, Paper, Stack } from "@mui/material";
import { ModalOrder } from "../components";

export function MeusPedidosPage() {
  const app = useContext(AppContext);
  const { getOrders } = useWordpress();

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<any[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<any>();

  useEffect(() => {
    const loadData = async () => {
      const data = await getOrders();
      setOrders(data);
      setLoading(false);
    };
    if (loading) loadData();
  }, [loading]);

  const styles = useStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
    },
    pageTitle: {
      textAlign: "center",
      fontSize: "48px",
      color: app.theme.palette.primary.main,
    },
    orderList: {},
    orderItem: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      gap: "30px",
      padding: "30px",
    },
    info: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      flexGrow: 50,
    },
    name: {
      fontSize: "18px",
    },
    date: {},
    btnWrapper: {
      // flexGrow: 20,
    },
  })();

  return (
    <div className={styles.root}>
      <div className={styles.pageTitle}>
        <span>Meus Pedidos</span>
      </div>
      {Boolean(selectedOrder) ? (
        <ModalOrder
          onClose={() => setSelectedOrder(undefined)}
          open={true}
          order={selectedOrder}
        />
      ) : (
        <>Nenhum pedido feito por enquanto =)</>
      )}
      {loading ? (
        <CircularProgress />
      ) : (
        <Stack spacing={2}>
          {orders.map((x) => (
            <Paper elevation={4} className={styles.orderItem}>
              <div className={styles.info}>
                <div className={styles.name}>
                  <Button
                    variant="text"
                    onClick={() => setSelectedOrder(x)}
                    style={{ padding: "0px" }}
                  >
                    Pedido #{x.id}
                  </Button>
                </div>
              </div>
              <div className={styles.btnWrapper}>
                <Button
                  variant="contained"
                  style={{ height: "fit-content" }}
                  onClick={() => setSelectedOrder(x)}
                >
                  Detalhes
                </Button>
              </div>
            </Paper>
          ))}
        </Stack>
      )}
    </div>
  );
}
