import React, { useState } from "react";
import { createTheme, Snackbar, Theme, ThemeProvider, useMediaQuery } from "@mui/material";
import EventEmitter from "events";
import { Address } from "./models";
import json2mq from "json2mq";

export const AppContext = React.createContext<AppContextProps>({} as any);

const themeKey = 'mcs-theme';
const userKey = 'mcs-user';
const addressKey = 'mcs-address';

interface AppUser {
  id: string,
  name: string,
  email: string,
}

export interface AppContextProps {
  addDropListener: (handler: any) => void,
  clearOutDropListeners: () => void,
  emitDrop: () => void,
  
  addNotifListener: (handler: (type: 'success' | 'error' | 'info' | 'warning', message: string) => void) => void,
  clearOutNotifListeners: () => void,
  notification: (type: 'success' | 'error' | 'info' | 'warning', message: string) => void,
  
  reRenderApp: () => void,
  toggleTheme: () => void,
  isDarkTheme: () => boolean,
  theme: Theme,
  
  setLoading: (loading: boolean) => void,
  loading: boolean,
  user?: AppUser,
  setUser: (user?: AppUser) => void,
  address?: Address,
  setAddress: (address?: Address) => void,

  xl: boolean,
  lg: boolean,
  md: boolean,
  sm: boolean,
  xs: boolean,
  xxs: boolean,
}

const appEvents = new EventEmitter();

export function AppContextProvider(props: AppContextProviderProps) {
  const themeValue = sessionStorage.getItem(themeKey);

  const [refresh, setRefresh] = useState(true);
  const [isDarkTheme, setIsDarkTheme] = useState((themeValue && themeValue === 'true') || false);
  const [loadingState, setLoadingState] = useState(false);
  const [user, setUser] = useState<AppUser | undefined>(
    sessionStorage.getItem(userKey) ?
      JSON.parse(sessionStorage.getItem(userKey)!) as AppUser
      :
      undefined
  );
  const [address, setAddress] = useState<Address | undefined>(
    sessionStorage.getItem(addressKey) ?
      JSON.parse(sessionStorage.getItem(addressKey)!) as Address
      :
      undefined
  );

  const xl = useMediaQuery(json2mq({ minWidth: 1501, }));
  const lg = useMediaQuery(json2mq({ minWidth: 1201, maxWidth: 1500, }));
  const md = useMediaQuery(json2mq({ minWidth: 901, maxWidth: 1200 }));
  const sm = useMediaQuery(json2mq({ minWidth: 601, maxWidth: 900, }));
  const xs = useMediaQuery(json2mq({ minWidth: 301, maxWidth: 600, }));
  const xxs = useMediaQuery(json2mq({ maxWidth: 300, }));

  const body = document.body;
  if (isDarkTheme) body.classList.add('boh-dark');
  else body.classList.remove('boh-dark');

  const appContextValue: AppContextProps = {
    addDropListener: (handler) => appEvents.addListener('drop', handler),
    clearOutDropListeners: () => appEvents.removeAllListeners('drop'),
    emitDrop: () => appEvents.emit('drop'),
    
    addNotifListener: (handler) => appEvents.addListener('notification', handler),
    clearOutNotifListeners: () => appEvents.removeAllListeners('notification'),
    notification: (type, message) => appEvents.emit('notification', type, message),
    
    reRenderApp: () => {
      setRefresh(!refresh)
    },
    isDarkTheme: () => isDarkTheme,
    toggleTheme: () => {
      sessionStorage.setItem(themeKey, !isDarkTheme + '');
      setIsDarkTheme(!isDarkTheme);
    },
    theme: getTheme(isDarkTheme),
    setLoading: (loading) => {
      if (loading) document.body.style.overflow = 'hidden';
      else document.body.style.overflow = 'auto';
      setLoadingState(loading);
    },
    loading: loadingState,
    user,
    setUser: (user) => {
      if (user) sessionStorage.setItem(userKey, JSON.stringify(user));
      else sessionStorage.removeItem(userKey);
      setUser(user);
    },
    address,
    setAddress: (address) => {
      if (address) sessionStorage.setItem(addressKey, JSON.stringify(address));
      else sessionStorage.removeItem(addressKey);
      setAddress(address);
    },
    lg,
    md,
    sm,
    xl,
    xs,
    xxs,
  }

  return <AppContext.Provider value={appContextValue} key={String(refresh)}>
    <ThemeProvider theme={getTheme(isDarkTheme)}>
      {props.children}
    </ThemeProvider>
  </AppContext.Provider>;
}

export interface AppContextProviderProps {
  children: any
}

function getTheme(dark = false): Theme {
  // const tertiary = '#F5A25E';
  const lightNeutral = '#1F1F3B';
  const darkNeutral = '#EAEBEE';

  const theme = createTheme({

    palette: {
      // mode: dark ? 'dark' : 'light',
      primary: {
        main: '#8102ce',
      },
      secondary: {
        main: '#ffd917',
        dark: pSBC(-0.3, '#ffd917'),
        light: pSBC(0.4, '#ffd917'),
      },
      // background: {
      //   default: dark ? lightNeutral : darkNeutral
      // },
      info: {
        main: '#32324C',
      },
      // text: {
      //   primary: dark ? darkNeutral : lightNeutral,
      //   secondary: dark ? lightNeutral : darkNeutral,
      // },
    },
    //  components: {
    //    // MuiAccordion: { defaultProps: { tr } },
    //    MuiButton: { defaultProps: { disableRipple: true, size: 'small', } },
    //    MuiIconButton: { defaultProps: { disableRipple: true } },
    //    MuiListItemButton: { defaultProps: { disableRipple: true } },
    //    MuiButtonBase: { defaultProps: { disableRipple: true } },
    //    MuiTooltip: { defaultProps: { disableInteractive: true } },
    //  },
  });



  return theme;
}

export const pSBC = (p: number, c0: string, c1?: any, l?: any): string => {
  /* eslint-disable */
  let r, g, b, P, f, t, h, i = parseInt, m = Math.round, a = typeof (c1) == "string";
  if (typeof (p) != "number" || p < -1 || p > 1 || typeof (c0) != "string" || (c0[0] != 'r' && c0[0] != '#') || (c1 && !a)) return '';
  //@ts-ignore
  let pSBCr = (d) => {
    let n = d.length, x = {};
    if (n > 9) {
      [r, g, b, a] = d = d.split(","), n = d.length;
      if (n < 3 || n > 4) return null;
      //@ts-ignore
      x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4)), x.g = i(g), x.b = i(b), x.a = a ? parseFloat(a) : -1
    } else {
      if (n == 8 || n == 6 || n < 4) return null;
      if (n < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
      d = i(d.slice(1), 16);
      //@ts-ignore
      if (n == 9 || n == 5) x.r = d >> 24 & 255, x.g = d >> 16 & 255, x.b = d >> 8 & 255, x.a = m((d & 255) / 0.255) / 1000;
      //@ts-ignore
      else x.r = d >> 16, x.g = d >> 8 & 255, x.b = d & 255, x.a = -1
    } return x
  };
  //@ts-ignore
  h = c0.length > 9, h = a ? c1.length > 9 ? true : c1 == "c" ? !h : false : h, f = pSBCr(c0), P = p < 0, t = c1 && c1 != "c" ? pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }, p = P ? p * -1 : p, P = 1 - p;
  if (!f || !t) return '';
  //@ts-ignore
  if (l) r = m(P * f.r + p * t.r), g = m(P * f.g + p * t.g), b = m(P * f.b + p * t.b);
  //@ts-ignore
  else r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5), g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5), b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
  //@ts-ignore
  a = f.a, t = t.a, f = a >= 0 || t >= 0, a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;
  //@ts-ignore
  if (h) return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")";
  //@ts-ignore
  else return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)
};
 /* eslint-enable */