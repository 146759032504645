import { CircularProgress } from '@mui/material';
import { useContext } from 'react';
import { AppContext } from '../app.context';
import { useStyles } from '../services';

export function Loading() {
  const app = useContext(AppContext);

  const styles = useStyles({
    root: {
      height: '100%',
      width: '100%',
      backdropFilter: 'blur(4px)',
      zIndex: 9999,
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })();

  return <div className={styles.root}>
    <CircularProgress size={100} />
  </div>;
}
