import { Paper, Modal, Grid, Button, Stack, Select, MenuItem, FormControl, InputLabel, Box, IconButton } from '@mui/material';
import {
  Close as CloseIcon
} from '@mui/icons-material';
import { useContext, useState } from 'react';
import { flatten } from 'lodash';

import { AppContext } from '../app.context';
import { Categories, phonesConfig } from '../contants';
import { addToCart, useStyles } from '../services';

interface ModalProductDetailProps {
  product: any,
  open: boolean,
  type: 'case' | 'accessory',
  onClose: () => void,
}

export function ModalProductDetail(props: ModalProductDetailProps) {
  const app = useContext(AppContext);
  const { product } = props;

  const [model, setModel] = useState('');

  const styles = useStyles({
    root: (data: any) => data.small?
    {
      padding: '30px',
    }
    :
    {
      width: '60%',
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '800px',
      padding: '20px'
    },
    img: {
      height: '400px',
    },
  })({
    small: app.xxs || app.xs
  });

  const categories = (product.categories || []).map((x: any) => x.id);
  const filterableCat = Object.values(Categories).filter(x => x.pickModelBy);
  const filterByCat = filterableCat.find(x => categories.includes(x.value));

  return <Modal open={props.open} onClose={() => props.onClose()} style={{ overflow: 'auto' }}>
    <Paper className={styles.root}>
      <Grid container spacing={3}>
        {
          app.xxs || app.xs ?
          <Grid item xs={12} display='flex' flexDirection={'row-reverse'}>
            <IconButton color='default' onClick={() => props.onClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
          :
          <></>
        }
        <Grid item xs={12} sm={6}>
          <img className={styles.img} alt='' src={product.images[0].src} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={4}>
            <h2>{product.name}</h2>
            <div style={{ marginTop: '0px' }} dangerouslySetInnerHTML={{ __html: product.description }}></div>

            {filterByCat &&
              <Grid container>
                <Grid item xs={12} sm={6} padding='0px 10px'>
                  <FormControl fullWidth>
                    <InputLabel id="model-label">Modelo</InputLabel>
                    <Select labelId="model-label" value={model} label="Modelo"
                      onChange={(ev) => setModel(ev.target.value)}
                    >
                      <MenuItem value={''}><span> </span></MenuItem>
                      {flatten(phonesConfig
                        .filter(x => x.brand === filterByCat.key)
                        .map(x => x.models))
                        .map(x => <MenuItem value={x.value}>{x.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            }
            <Stack flexDirection={'row'}>
              <Button variant='contained'
                disabled={props.type === 'case' ? !model : false}
                onClick={() => {
                  props.onClose();
                  addToCart({
                    id: product.id,
                    name: product.name,
                    description: product.description || '',
                    price: Number(product.price),
                    type: props.type,
                    imageUrl: product.images.length > 0 ? product.images[0].src : '',
                    ...filterByCat ? { brand: filterByCat.key } : undefined,
                    ...model ? { model: model } : undefined,
                  });
                  app.notification('success', 'Produto adicionado ao carrinho!');
                }}
              >
                Adicionar ao carrinho
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  </Modal>;
}
