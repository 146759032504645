import { useContext } from "react";
import { AppConfig, Categories } from "../contants";
import { getCartItems } from "./cart";
import { httpGet, httpPost } from "./http";
import { AppContext } from "../app.context";

export function useWordpress() {
  const app = useContext(AppContext);
  return {
    getOrders: async () => {
      return await httpGet(
        `${AppConfig.wordpressUrl}/wp-json/wc/v3/orders?customer=${
          app.user?.id
        }&_=${Date.now()}`,
        { wordpressReq: true }
      );
    },
    getOrderProducts: async (order: any) => {
      const ids: string[] = [];
      order.line_items.forEach((x: any) => ids.push(x.product_id));

      return await httpGet(
        `${AppConfig.wordpressUrl}/wp-json/wc/v3/products?include=${String(
          ids
        )}&_=${Date.now()}`,
        { wordpressReq: true }
      );
    },
  };
}
