import { createBrowserHistory } from 'history';
import { useContext } from 'react';
import { createUseStyles, DefaultTheme, Styles } from 'react-jss';

import { generateRandomStr } from '.';
import { AppContext } from '../app.context';

export const history = createBrowserHistory();

export const isLocal = () => window.location.host.startsWith('localhost');

//========================
// Routing
//========================
export function useNavigation() {
  const app = useContext(AppContext);
  return {
    navigate: (route: string, state?: any, scrollToTop?: boolean) => {
      history.push(route, state);
      app.reRenderApp();
      if (scrollToTop) window.scrollTo( { top: 0, left: 0, behavior: 'smooth' });
    },
  }
}
//========================

//========================
// Styles
//========================
export function useStyles<C extends string = string, Props = unknown, Theme = DefaultTheme>(styles: Styles<C, Props, Theme> | ((theme: Theme) => Styles<C, Props, undefined>)) {
  return createUseStyles(styles,
  {
    generateId: () => 'aoj-' + generateRandomStr(10)
  });
}
//========================

//========================
// Error Handling
//========================
export class CustomError {
  message: string;
  constructor(message: string) {
    this.message = message;
  }
}

export function handleError(error: any) {
  if (error && error.constructor.name === 'CustomError') {
    //notification((error as CustomError).message, NotificationType.Error);
  }
  else {
    //notification('An error occurred. Please contact the system admin', NotificationType.Error);
  }
}
//========================