import { useContext } from "react";

import { AppContext } from "../app.context";
import { useStyles } from "../services";

export function PerfilPage() {
  const app = useContext(AppContext);

  const styles = useStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
    },
    pageTitle: {
      textAlign: "center",
      fontSize: "48px",
      color: app.theme.palette.primary.main,
    },
  })();

  return (
    <div className={styles.root}>
      <div className={styles.pageTitle}>
        <span>Perfil</span>
      </div>
    </div>
  );
}
