import { useContext } from 'react';

import { AppContext } from '../app.context';
import { ProductItemList } from '../components';
import { Categories } from '../contants';
import { useStyles } from '../services';

export function AcessoriosPage() {
    const app = useContext(AppContext);

    const styles = useStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: '50px',
        },
        pageTitle: {
            textAlign: 'center',
            fontSize: '48px',
            color: app.theme.palette.primary.main,
        },
    })();

    return (
        <div className={styles.root}>
            <div className={styles.pageTitle}>
                <span>Acessorios</span>
            </div>
            <ProductItemList resource='products' category='33'
                type='accessory'
                filters={[
                    {
                        name: 'Tipo',
                        filterOpts: [
                            Categories.Cabos,
                            Categories.Carregador,
                            Categories.FoneDeOuvido,
                            Categories.Pelicula,
                            Categories.ProtetorDeCabos,
                        ]
                    },
                ]}
            />
        </div>
    );
}