import {
  CSSProperties,
  KeyboardEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Checkbox, styled, TextFieldProps } from "@mui/material";
import { FormControlLabel, RadioGroup, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import IMask from "imask";

import { AppContext } from "../app.context";

const Div = styled("div")(({ theme }) => ({
  color: theme.palette.text.primary,
}));

type FormFieldType =
  | "text"
  | "number"
  | "checkbox"
  | "date"
  | "radio"
  | "password"
  | "textarea"
  | "select"
  | "array";

interface FormFieldProps<T> {
  field: keyof T;
  form: FormObj<T>;
  children?: JSX.Element[];
  variant?: "outlined" | "filled" | "standard";
  placeholder?: string;
  mask?: string;
  rows?: number;
  row?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  id?: string;
  onChanged?: (obj: Partial<T>) => void;
  onKeyPressed?: KeyboardEventHandler;
  customValidator?: (rule: useFormProps<T>) => string;
}

export function FormField<T>(props: FormFieldProps<T>) {
  const inputRef = useRef(null);
  const { palette } = useContext(AppContext).theme;
  if (!props.form.rules[props.field]) return <></>;

  const rule = props.form.rules[props.field];
  const validation = props.form.validateField(props.field);

  const newObj = (value: any) => ({
    ...props.form.values,
    [props.field]: value,
  });

  const textProps = {
    id: props.id,
    label: rule.label,
    required: rule.required,
    variant: props.variant || "outlined",
    value: props.form.values[props.field] || "",
    type: rule.type,
    error: validation.msg ? true : false,
    helperText: validation.msg || "",
    multiline: rule.type === "textarea",
    rows: props.rows,
    ref: inputRef,
    disabled: props.disabled,
    onChange: (ev) => {
      props.form.setField(props.field, ev.target.value);
      if (props.onChanged) props.onChanged(newObj(ev.target.value));
    },
    InputLabelProps: { style: { color: palette.text.primary } },
    style: { width: "100%" },
    onKeyDown: props.onKeyPressed,
  } as TextFieldProps;

  // eslint-disable-next-line
  useEffect(() => {
    if (props.mask && inputRef && inputRef.current) {
      const input = (inputRef.current as any).querySelector("input");
      IMask(input, { mask: props.mask });
    }
  }, []);

  switch (rule.type) {
    case "textarea":
    case "password":
    case "text":
      return (
        <Div style={props.style}>
          <TextField
            {...textProps}
            inputProps={{ style: props.inputStyle }}
            placeholder={props.placeholder}
          />
        </Div>
      );
    case "radio":
      return (
        <RadioGroup
          style={props.style}
          id={props.id}
          defaultValue={props.form.values[props.field] || ""}
          row={props.row}
          name={props.field as string}
          onChange={(ev) => {
            props.form.setField(props.field, ev.target.value);
            if (props.onChanged) props.onChanged(newObj(ev.target.value));
          }}
        >
          {props.children}
        </RadioGroup>
      );
    case "checkbox":
      return (
        <FormControlLabel
          label={rule.label}
          style={props.style}
          control={
            <Checkbox
              checked={props.form.values[props.field] as boolean}
              id={props.id}
              onChange={(ev) => {
                props.form.setField(props.field, ev.target.checked);
                if (props.onChanged) props.onChanged(newObj(ev.target.value));
              }}
              inputProps={{ style: { color: palette.text.primary } }}
            />
          }
          disabled={props.disabled}
        />
      );
    case "date":
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            InputAdornmentProps={{
              style: { color: palette.text.primary },
              id: props.id,
            }}
            label={rule.label}
            value={props.form.values[props.field] || undefined}
            onChange={(args) => {
              if (!args) return;
              props.form.setField(
                props.field,
                new Date(args?.toString()!).toISOString()
              );
              if (props.onChanged)
                props.onChanged(newObj(new Date(args?.toString())));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      );
    default:
      return <span style={props.style}>To be implemented</span>;
  }
}

interface FormObj<T> {
  rules: useFormProps<T>;
  values: T;
  setField: (field: keyof T, value: any) => void;
  set: (data: Partial<T>) => void;
  validateField: (field: keyof T) => FieldValidation;
  isValid: () => boolean;
  reset: () => void;
}

export type useFormProps<T> = {
  [key in keyof Partial<T>]: {
    label: string;
    type: FormFieldType;
    required?: boolean;
    minValue?: number;
    maxValue?: number;
    minLength?: number;
    maxLength?: number;
    regex?: RegExp;
    allowFuture?: boolean;
    allowPast?: boolean;
    allowCurrent?: boolean;
    customValidator?: (value: any) => string | undefined;
  };
};

type FieldValidation = { valid: boolean; msg: string };

export function useForm<T>(data: T, rules: useFormProps<T>): FormObj<T> {
  const [input, setInput] = useState<T | Partial<T>>(data);

  const validateField = (field: keyof T): FieldValidation => {
    const rule = rules[field];
    if (!rule) return { valid: true, msg: "" };
    if (rule.type === "checkbox") return { valid: true, msg: "" };

    let value = (input as any)[field];
    if (typeof value === "string") value = value.trim();

    if (typeof value === "undefined") return { valid: false, msg: "" };

    if (rule.required && !value)
      return { valid: false, msg: "Este campo é obrigatório" };

    if (typeof value === "string") {
      if (rule.minLength && value.length < rule.minLength)
        return {
          valid: false,
          msg: `Deve ser maior que ${rule.minLength} caracteres`,
        };
      if (rule.maxLength && value.length > rule.maxLength)
        return {
          valid: false,
          msg: `Nao exceder ${rule.maxLength} caracteres`,
        };
      if (rule.regex && !value.match(rule.regex))
        return { valid: false, msg: `Formato invalido` };
    }

    if (typeof value === "number") {
      if (rule.minValue && value < rule.minValue)
        return {
          valid: false,
          msg: `O valor deve ser acima de ${rule.minValue}`,
        };
      if (rule.maxValue && value > rule.maxValue)
        return {
          valid: false,
          msg: `O valor deve ser abaixo de ${rule.maxValue}`,
        };
    }

    if (rule.customValidator && rule.customValidator(value))
      return { valid: false, msg: rule.customValidator(value) || "" };

    return { valid: true, msg: "" };
  };

  return {
    rules,
    values: input as T,
    setField: (field: keyof T, value: any) =>
      setInput({ ...input, [field]: value }),
    set: (data: Partial<T>) => setInput({ ...data }),
    validateField,
    isValid: () =>
      Object.keys(rules).every(
        (field) => validateField(field as keyof T).valid
      ),
    reset: () => setInput({} as any),
  };
}
