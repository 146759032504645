import { Button, Fab, Grid, Popover, Stack, Tooltip, Typography, } from '@mui/material';
import {
  Cancel as DeleteIcon,
  Edit as EditIcon,
  ArrowCircleLeft as LeftIcon,
  ArrowCircleRight as RightIcon,
  RotateRight as RotateRightIcon,
  RotateLeft as RotateLeftIcon,
  ZoomInMap as ZoomInIcon,
  ZoomOutMap as ZoomOutIcon,
  AddCircleRounded as AddIcon,
  Check as ConfirmIcon,
} from '@mui/icons-material';

import { useContext, useRef, useState } from 'react';
import { AppContext } from '../app.context';
import {
  ProductImagesFull,
  ProductImagesFiftyFifty,
  ProductImagesThree,
  ProductImagesFour,
  ProductImagesSix,
  ProductImagesLargeTopTwoBottom,
  ProductImagesFourTopLargeBottom,
  Product,
} from '../models';
import { showStepper, sleep, useStyles } from '../services';

interface ImageContainerProps {
  id: string,
  className: string,
  field: keyof ProductImagesFull
  | keyof ProductImagesFiftyFifty
  | keyof ProductImagesThree
  | keyof ProductImagesFour
  | keyof ProductImagesSix
  | keyof ProductImagesLargeTopTwoBottom
  | keyof ProductImagesFourTopLargeBottom,
  product: Product,
  onProductChanged: (newProd: Product) => void,
}

const focusedBorder = '1px solid white';

export function ImageContainer(props: ImageContainerProps) {
  const app = useContext(AppContext);
  const { product, field, onProductChanged } = props;

  const small = app.xxs || app.xs || app.sm;

  const inputEl = useRef<HTMLInputElement>(null);
  const imgEl = useRef<HTMLImageElement>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  const [zoom, setZoom] = useState(100);
  const [marginLeft, setMarginLeft] = useState(0);
  const [marginTop, setMarginTop] = useState(0);
  const [imgWidth, setImgWidth] = useState('');
  const [imgHeight, setImgHeight] = useState('');
  const [showBtns, setShowBtns] = useState(false);

  const [currBg, setCurrBg] = useState<string>(product.images ? product.images[field] : undefined);

  const styles = useStyles({
    rootBg: {
      zIndex: (data: any) => data.anchorEl ? '1100' : '50',
      position: 'absolute',
    },
    rootFocus: {
      zIndex: (data: any) => data.anchorEl ? '1101' : '200',
      position: 'absolute',
      border: focusedBorder,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    },
    background: {
      height: (data: any) => data.imgHeight,
      width: (data: any) => data.imgWidth,
      marginLeft: (data: any) => `${data.marginLeft}px`,
      marginTop: (data: any) => `${data.marginTop}px`,
      // marginLeft: (data: any) => `-${data.imgWidth / 4}px`,
      // marginTop: (data: any) => `-${data.imgHeight / 4}px`,
    },
    fab: {
      margin: '6px 1px !important',
      height: '50px !important',
      width: '50px !important',
    },
    controlsBtn: {
      height: '50px !important',
      width: '50px !important',
    },
    smallPopover: {
      top: 'unset !important',
      left: '10px !important',
      right: '10px !important',
      bottom: '16px !important'
    },
  })({
    anchorEl, zoom, marginLeft, marginTop, imgWidth, imgHeight
  });

  const clearOutBg = () => {
    setCurrBg('');
    app.clearOutDropListeners();

    if (!product.images) product.images = {} as any;
    delete product.images[field];
    onProductChanged(product);
  };

  const handleFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      const decodedImg = reader.result as string;
      if (decodedImg.length > 5000000) {
        alert('Maximum image size 4MB');
      }
      else {
        setCurrBg(decodedImg);

        const i = new Image();
        i.onload = () => {
          console.log(`Image Loaded: ${i.width + ", " + i.height}`);

          if (i.width > i.height) {
            setImgWidth('auto');
            setImgHeight('100%');
          }
          else if (i.width < i.height) {
            setImgHeight('auto');
            setImgWidth('100%');
          }
          else {
            setImgHeight('100%');
            setImgWidth('100%');
          }

        };
        i.src = decodedImg;

        if (!product.images) product.images = {} as any;
        product.images[field] = decodedImg;
        onProductChanged(product);
      }
    };
    reader.readAsDataURL(file);
  };

  const arrangeBtns = <Stack flexDirection='row' gap='10px'>
    <Fab color="default" className={`${styles.controlsBtn}`}
      onClick={(ev) => {
        ev.stopPropagation();
        const newMargin = marginTop - 20;
        setMarginTop(newMargin);
      }}
    >
      <Tooltip title='Mover pra cima'>
        <LeftIcon style={{ transform: 'rotate(90deg)' }} />
      </Tooltip>
    </Fab>
    <Fab color="default" className={`${styles.controlsBtn}`}
      onClick={(ev) => {
        ev.stopPropagation();
        const newMargin = marginTop + 20;
        setMarginTop(newMargin);
      }}
    >
      <Tooltip title='Mover pra baixo'>
        <RightIcon style={{ transform: 'rotate(90deg)' }} />
      </Tooltip>
    </Fab>
    <Fab color="default" className={`${styles.controlsBtn}`}
      onClick={(ev) => {
        ev.stopPropagation();
        const newMargin = marginLeft - 20;
        setMarginLeft(newMargin);
      }}
    >
      <Tooltip title='Mover pra esquerda'>
        <LeftIcon />
      </Tooltip>
    </Fab>
    <Fab color="default" className={`${styles.controlsBtn}`}
      onClick={(ev) => {
        ev.stopPropagation();
        const newMargin = marginLeft + 20;
        setMarginLeft(newMargin);
      }}
    >
      <Tooltip title='Mover pra direita'>
        <RightIcon />
      </Tooltip>
    </Fab>
  </Stack>;
  const resizeBtns = <Stack flexDirection='row' gap='10px'>
    <Fab color="default" className={`${styles.controlsBtn}`}
      onClick={(ev) => {
        ev.stopPropagation();
        const newZoom = zoom + 20;
        setZoom(newZoom);
        if (imgWidth === 'auto') setImgHeight(`${newZoom}%`);
        else setImgWidth(`${newZoom}%`);
      }}
    >
      <Tooltip title='Zoom In'>
        <ZoomInIcon />
      </Tooltip>
    </Fab>
    <Fab color="default" className={`${styles.controlsBtn}`}
      onClick={(ev) => {
        ev.stopPropagation();
        const newZoom = zoom - 20;
        setZoom(newZoom);
        if (imgWidth === 'auto') setImgHeight(`${newZoom}%`);
        else setImgWidth(`${newZoom}%`);
      }}
    >
      <Tooltip title='Zoom Out'>
        <ZoomOutIcon />
      </Tooltip>
    </Fab>
  </Stack>;
  const confirmBtns = <>
  {
    small ?
      <Fab color='primary'onClick={(ev) => {
        ev.stopPropagation();
        setAnchorEl(undefined)
      }}>
        <ConfirmIcon />
      </Fab>
      :
      <div>
        <Button variant='contained' onClick={(ev) => {
          ev.stopPropagation();
          setAnchorEl(undefined)
        }}>Confirmar</Button>
      </div>
  }
  </>;

  return <>
    <div className={`${styles.rootBg} ${props.className}`}
      style={{
        overflow: anchorEl ? 'initial' : 'hidden'
      }}
    >
      <img src={currBg} className={styles.background} ref={imgEl} />
    </div>
    <div className={`${styles.rootFocus} ${props.className}`}
      onClick={(ev) => {
        if (anchorEl) return;
        setShowBtns(!showBtns);
      }}
      style={{ cursor: 'pointer' }}
    >
      {
        showBtns ?
          currBg ?
            <>
              <Fab color="error"
                className={styles.fab}
                onClick={(ev) => {
                  ev.stopPropagation();
                  clearOutBg();
                  setShowBtns(false);
                }}
              >
                <DeleteIcon />
              </Fab>
              <Fab color="info"
                className={styles.fab}
                onClick={async (ev) => {
                  ev.stopPropagation();
                  const target = ev.currentTarget.parentElement as HTMLDivElement;
                  setAnchorEl(target);

                  if (small) {
                    const phone = document.querySelector(`#mcs-phone-container`);
                    if (phone) phone.scrollIntoView(true);
                  }
                  else {
                    showStepper();
                  }
                  setShowBtns(false);
                }}
              >
                <EditIcon />
              </Fab>
            </>
            :
            <Fab color={'success'}
              className={styles.fab}
              onClick={(ev) => {
                ev.stopPropagation();
                if (currBg) return;
                if (inputEl.current) inputEl.current.click();
                setShowBtns(false);
              }}
            >
              <AddIcon />
            </Fab>
          :
          <></>
      }
      {anchorEl &&
        <Popover open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          hideBackdrop={true}
          slotProps={{ backdrop: { style: { backdropFilter: 'blur(5px)' } }, }}
          {...small ?
            {
              // anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              PaperProps: {
                className: styles.smallPopover,
              },
            }
            :
            {
              anchorOrigin: { vertical: 'top', horizontal: 'right', },
              onClose: () => setAnchorEl(undefined),
            }
          }
        >
          <Grid container
            alignItems={'center'}
            padding={'20px 40px'}

            {...small ?
              { justifyContent: 'center', spacing: 5 }
              :
              { spacing: 3 }}
          >
            {
              small ?
              <>
                <Grid item xs={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>{arrangeBtns}</Grid>
                    <Grid item xs={12}>{resizeBtns}</Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  {confirmBtns}
                </Grid>
              </>
              :
              <>
                <Grid item xs={6} sm={4}  display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
                  <h2>Mover</h2>
                  {arrangeBtns}
                </Grid>
                <Grid item xs={6} sm={4}  display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
                  <h2>Redimensionar</h2>
                  {resizeBtns}
                </Grid>
                <Grid item xs={6} sm={4} display='flex' flexDirection='row-reverse'>
                  {confirmBtns}
                </Grid>
              </>
            }
          </Grid>
        </Popover>
      }
    </div>

    <input type="file" ref={inputEl}
      style={{ display: 'none' }}
      onChange={(ev) => {
        handleFile(ev.currentTarget.files![0]);
        //@ts-ignore
        ev.currentTarget.value = null;
      }}
      accept="image/*"
    />
  </>;
}
