import { Button, ButtonGroup, Checkbox, CircularProgress, Drawer, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
  FormatListBulleted,
  CalendarViewMonth,
} from '@mui/icons-material';
import * as _ from 'lodash';

import { ModalProductDetail, ProductItemCard, ProductItemRow } from '.';
import { AppContext } from '../app.context';
import { httpGet, useStyles } from '../services';
import { AppConfig, Category } from '../contants';
import json2mq from 'json2mq';

const rowsPerPageOpts = [12, 24, 36];

interface ProductItemListProps {
  resource: string,
  category: string,
  type: 'case' | 'accessory',
  filters?: {
    name: string,
    filterOpts: Category[],
  }[],
}

export function ProductItemList(props: ProductItemListProps) {
  const app = useContext(AppContext);

  const isSmall = useMediaQuery(json2mq({ maxWidth: 500, }));

  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOpts[0]);
  const [products, setProducts] = useState<any[]>([]);
  const [tagsToFilter, setTagsToFilter] = useState<number[]>([]);
  const [currPage, setCurrPage] = useState(1);
  const [rowsView, setRowsView] = useState<'card' | 'row'>('card');
  const [detailProduct, setDetailProduct] = useState<any>();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const queryUrl = (category?: string, tag?: string) => {
    return `${AppConfig.wordpressUrl}/wp-json/wc/v3/${props.resource}?per+page=100&page=${currPage}&category=${category ? category : props.category}${tag ? `&tag=${tag}` : ''}`;
  }

  useEffect(() => {
    const loadData = async () => {
      let loadedProducts: any[] = [];
      const data = await httpGet(queryUrl(), { returnReqObj: true, wordpressReq: true });
      loadedProducts = JSON.parse(data.response);
      setProducts(_.uniqBy(_.flatten(loadedProducts), 'id'));
      setLoading(false);
    };
    if (loading) loadData();
  }, [loading]);

  const styles = useStyles({
    root: {
    },
    listContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0px 60px',
      gap: '10px',
    },
    listContainerSmall: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0px 60px',
      gap: '10px',
    },
    paginationContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
    },
    paginationBtn: {

    },
  })();

  const sliceStart = (currPage - 1) * rowsPerPage;
  const sliceEnd = sliceStart + rowsPerPage;

  const filteredProducts = tagsToFilter.length > 0 ?
    products.filter(x => x.categories.map((y: any) => y.id).find((y: any) => tagsToFilter.includes(y)))
    :
    products;

  const paginatedProducts = filteredProducts.slice(sliceStart, sliceEnd);

  const numberOfPages = Math.ceil(filteredProducts.length / rowsPerPage);

  return <div className={styles.root}>
    <Grid container>
      {
        app.xl || app.lg || app.md ?
          <>
            <Grid item md={3}>
              <Stack spacing={3}>
                {
                  props.filters?.map(x => <FormControl variant="standard" key={x.name}>
                    <FormLabel component="legend">{x.name}</FormLabel>
                    <FormGroup>
                      {x.filterOpts.map(y => <FormControlLabel key={y.key}
                        control={
                          <Checkbox name={y.label}
                            onChange={(ev) => {
                              if (ev.target.checked) {
                                if (!tagsToFilter.includes(y.value)) {
                                  tagsToFilter.push(y.value);
                                  setTagsToFilter(Array.from(tagsToFilter));
                                }
                              }
                              else {
                                if (tagsToFilter.includes(y.value)) {
                                  tagsToFilter.splice(tagsToFilter.indexOf(y.value), 1);
                                  setTagsToFilter(Array.from(tagsToFilter));
                                }
                              }
                              setCurrPage(1);
                            }}
                          />
                        }
                        label={y.label}
                      />)}
                    </FormGroup>
                  </FormControl>)
                }
              </Stack>
            </Grid>
            <Grid item md={9}>
              <Stack spacing={5}>
                <Grid container>
                  <Grid item md={3} lg={2}>
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="select-row-per-page">Produtos por pagina</InputLabel>
                      <Select value={rowsPerPage}
                        variant='standard'
                        style={{ color: 'black' }}
                        onChange={(e) => {
                          const newPageSize = parseInt(String(e.target.value));
                          setRowsPerPage(newPageSize);
                          setCurrPage(1);
                        }}
                        labelId="select-row-per-page"
                      >
                        {rowsPerPageOpts.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} lg={7}>

                  </Grid>
                  <Grid item md={3}>
                    <ButtonGroup >
                      <Button variant={rowsView === 'card' ? 'contained' : 'outlined'}
                        startIcon={<CalendarViewMonth />}
                        onClick={() => setRowsView('card')}>Card</Button>
                      <Button variant={rowsView === 'row' ? 'contained' : 'outlined'}
                        startIcon={<FormatListBulleted />}
                        onClick={() => setRowsView('row')}>Linha</Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
                {
                  loading ?
                    <CircularProgress />
                    :
                    <>

                      <div className={styles.listContainer}>
                        {
                          paginatedProducts.map(p =>
                            rowsView === 'card' ?
                              <ProductItemCard key={p.id} product={p} onSelect={() => setDetailProduct(p)} />
                              :
                              <ProductItemRow key={p.id} product={p} onSelect={() => setDetailProduct(p)} />
                          )
                        }
                      </div>
                      {detailProduct &&
                        <ModalProductDetail open={true} product={detailProduct} onClose={() => setDetailProduct(undefined)} type={props.type} />
                      }
                      <div className={styles.paginationContainer}>
                        {(() => {
                          if (numberOfPages <= 1) return null;
                          const paginationBtns: any[] = [];
                          for (let i = 1; i <= numberOfPages; i++) {
                            paginationBtns.push(
                              <Button className={styles.paginationBtn}
                                variant={currPage === i ? 'contained' : 'outlined'}
                                onClick={() => {
                                  setCurrPage(i);
                                  window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                                }}
                              >
                                {i}
                              </Button>);
                          }
                          return paginationBtns;
                        })()}
                      </div>
                    </>
                }
              </Stack>
            </Grid>
          </>
          :
          <>
            <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
              <Stack spacing={3}
                style={{ padding: '15px 20px' }}
              >
                {
                  props.filters?.map(x => <FormControl variant="standard" key={x.name}>
                    <FormLabel component="legend">{x.name}</FormLabel>
                    <FormGroup>
                      {x.filterOpts.map(y => <FormControlLabel key={y.key}
                        control={
                          <Checkbox name={y.label}
                            onChange={(ev) => {
                              if (ev.target.checked) {
                                if (!tagsToFilter.includes(y.value)) {
                                  tagsToFilter.push(y.value);
                                  setTagsToFilter(Array.from(tagsToFilter));
                                }
                              }
                              else {
                                if (tagsToFilter.includes(y.value)) {
                                  tagsToFilter.splice(tagsToFilter.indexOf(y.value), 1);
                                  setTagsToFilter(Array.from(tagsToFilter));
                                }
                              }
                              setCurrPage(1);
                            }}
                          />
                        }
                        label={y.label}
                      />)}
                    </FormGroup>
                  </FormControl>)
                }
              </Stack>
            </Drawer>
            <Grid item xs={12}>
              <Stack spacing={5}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button variant='contained'
                      onClick={() => setIsDrawerOpen(true)}
                    >
                      Filtros
                    </Button>
                  </Grid>
                  <Grid item flexGrow={1}> 
                  </Grid>
                  <Grid item flexGrow={1}>
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="select-row-per-page">Produtos por pagina</InputLabel>
                      <Select value={rowsPerPage}
                        variant='standard'
                        style={{ color: 'black' }}
                        onChange={(e) => {
                          const newPageSize = parseInt(String(e.target.value));
                          setRowsPerPage(newPageSize);
                          setCurrPage(1);
                        }}
                        labelId="select-row-per-page"
                      >
                        {rowsPerPageOpts.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {
                  loading ?
                    <CircularProgress />
                    :
                    <>

                      <div className={styles.listContainerSmall}>
                        {
                          paginatedProducts.map(p =>
                            <ProductItemCard key={p.id} product={p} onSelect={() => setDetailProduct(p)} />
                          )
                        }
                      </div>
                      {detailProduct &&
                        <ModalProductDetail open={true} product={detailProduct} onClose={() => setDetailProduct(undefined)} type={props.type} />
                      }
                      <div className={styles.paginationContainer}>
                        {(() => {
                          if (numberOfPages <= 1) return null;
                          const paginationBtns: any[] = [];
                          for (let i = 1; i <= numberOfPages; i++) {
                            paginationBtns.push(
                              <Button className={styles.paginationBtn}
                                variant={currPage === i ? 'contained' : 'outlined'}
                                onClick={() => {
                                  setCurrPage(i);
                                  window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                                }}
                              >
                                {i}
                              </Button>);
                          }
                          return paginationBtns;
                        })()}
                      </div>
                    </>
                }
              </Stack>
            </Grid>
          </>
      }
    </Grid>
  </div>;
}
