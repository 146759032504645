import { useContext } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import {
  LocalPhone as LocalPhoneIcon,
} from '@mui/icons-material';

import { AppContext } from '../app.context';
import { contactUs, useStyles } from '../services';
import { FormField, useForm } from '../components';

interface ContatoForm {
    name: string,
    email: string,
    phone: string,
    subject: string,
    message: string,
}

export function ContatoPage() {
    const app = useContext(AppContext);

    const form = useForm<ContatoForm>({} as any,
    {
        name: { type: 'text', label: 'Nome', minLength: 3, required: true },
        email: { type: 'text', label: 'E-mail', minLength: 3, required: true },
        phone: { type: 'text', label: 'Telefone', minLength: 3, required: true },
        subject: { type: 'text', label: 'Assunto', minLength: 3, required: true },
        message: { type: 'text', label: 'Mensagem', minLength: 10, required: true },   
    }
    );

    const styles = useStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: '50px',
        },
        pageTitle: {
            textAlign: 'center',
            fontSize: '48px',
            color: app.theme.palette.primary.main,
        },
        rightPanel: {
            padding: '40px 30px',
            height: '100%',
            color: 'white',
            backgroundColor: app.theme.palette.primary.main,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'center',
            gap: '30px',
        },
    })();

    return (
        <div className={styles.root}>
            <div className={styles.pageTitle}>
                <span>Fale Conosco</span>
            </div>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={7}>
                    <Grid container columns={12} spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <FormField form={form} field='name' variant='standard' />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormField form={form} field='email' variant='standard' />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormField form={form} field='phone' variant='standard' />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormField form={form} field='subject' variant='standard' />
                        </Grid>
                        <Grid item xs={12}>
                            <FormField form={form} field='message' variant='standard' />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained"
                                disabled={!form.isValid()}
                                onClick={async () => {
                                    app.setLoading(true);
                                    try {
                                        await contactUs(
                                            form.values.name!,
                                            form.values.email!,
                                            form.values.phone!,
                                            form.values.subject!,
                                            form.values.message!,
                                        );
                                        app.notification('success', 'Mensagem enviada com sucesso');
                                        form.set({} as any);
                                    }
                                    catch (error) {
                                        console.error(error);
                                        app.notification('error', 'Algo deu errado. Contate o administrator.');
                                    }
                                    
                                    app.setLoading(false);
                                }}
                            >
                                Enviar Menssagem
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={5} display='flex' flexDirection='column'>
                    <div className={styles.rightPanel}>
                        <LocalPhoneIcon style={{fontSize:'80px'}} />
                        <span style={{fontSize:'36px'}}>(13) 997790959</span>
                        <span><b>Horário de atendimento:</b> 09:00 – 18:00</span>
                        <span><b>Endereço:</b> São Paulo</span>
                        <span><b>Email:</b> contato@minhacase.com.br</span>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
