import { Product } from "../models";

const cartKey = 'mcs-cart';

export function addToCart(product: Product) {
  const cart = JSON.parse(sessionStorage.getItem(cartKey) || '[]') as any[];
  cart.push(product);
  sessionStorage.setItem(cartKey, JSON.stringify(cart));
}

export function getCartCount() {
  const cart = JSON.parse(sessionStorage.getItem(cartKey) || '[]') as any[];
  return cart.length;
}

export function getCartItems(): Product[] {
  const cart = JSON.parse(sessionStorage.getItem(cartKey) || '[]') as Product[];
  return cart;
}

export function getCartTotal() {
  const cart = JSON.parse(sessionStorage.getItem(cartKey) || '[]') as Product[];
  return Number(cart.map(x => x.price).reduce((s, a) => s + a, 0).toFixed(2));
}

export function removeCartItem(index: number) {
  const cart = JSON.parse(sessionStorage.getItem(cartKey) || '[]') as any[];
  cart.splice(index, 1);
  sessionStorage.setItem(cartKey, JSON.stringify(cart));
}

export function clearOutCart() {
  sessionStorage.setItem(cartKey, '[]');
}