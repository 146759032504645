import { Button } from '@mui/material';
import { green } from '@mui/material/colors';
import { useContext } from 'react';
import { AppContext } from '../app.context';
import { useStyles } from '../services';

interface ProductItemCardProps {
  product: any,
  onSelect: () => void,
}

export function ProductItemCard(props: ProductItemCardProps) {
  const app = useContext(AppContext);

  const { product } = props;

  const styles = useStyles({
    root: {
      width: (data: any) => {
        const width = data.xxs || data.xs ? '49' : data.sm ? '33' : '25';
        return `calc(${width}% - 10px)`;
      },
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      alignItems: 'center',
      position: 'relative',
      '& .item-backdrop': {
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        height: '100%',
        width: '100%',
        opacity: '0',
        transition: '.2s ease',
        backdropFilter: 'blur(3px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '&:hover .item-backdrop': {
        opacity: '1',
      },
    },
    productName: {
      fontSize: '18px',
    },
    productPrice: {
      color: green[500]
    },
  })({
    xxs: app.xxs,
    xs: app.xs,
    sm: app.sm,
  });

  return (
    <div className={styles.root}>
      <div className='item-backdrop'>
        <Button variant='contained' style={{ height: 'fit-content' }} onClick={() => props.onSelect()}>
          Comprar
        </Button>
      </div>
      <div>
        {
          !product.images || product.images.length <= 0 ?
            null
            :
            <img src={product.images[0].src} alt='' height='160px' />
        }
      </div>
      <div className={styles.productName}>{product.name}</div>
      <div className={styles.productPrice}>R$ {product.price}</div>
    </div>
  );
}
