import { Alert, IconButton, Snackbar } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';

import { AppContext } from '../app.context';
import { useStyles } from '../services';

export function Notification() {
  const app = useContext(AppContext);
  
  const [type, setType] = useState<'success' | 'error' | 'info' | 'warning'>();
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    app.addNotifListener((type, message) => {
      setType(type);
      setMessage(message);
    });
    return () => app.clearOutNotifListeners();
  });

  const handleClose = () => setMessage(undefined);

  return <Snackbar open={Boolean(message)} 
    sx={{ width: 600 }}
    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    autoHideDuration={6000}
    onClose={handleClose}
    action={
      <IconButton size="small" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    }
  >
    <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
    {message}
    </Alert>
  </Snackbar>;
}
