import ReactDOM from "react-dom";

import App from "./app";

import "./index.scss";
import "@fontsource/roboto/400.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./lib/mercado-pago.min.js";

const bodyEl = document.querySelector("body");
ReactDOM.render(<App />, bodyEl);
