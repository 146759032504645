import { Accordion, AccordionDetails, AccordionSummary, Box, Tab, Tabs, Typography } from "@mui/material";
import { useContext, useState } from "react";
import {
    ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { AppContext } from "../app.context";
import { useStyles } from "../services";

const materialsContent = [
    {
        question: 'TPU ANTI IMPACTO FLEXÍVEL TRANSPARENTE',
        text: `
        As Capas em TPU Anti Impacto Transparente (flexíveis) são super resistentes a
        impactos. Mantendo todos os lados do seu smartphone protegidos. Fabricadas em
        silicone macio, de fino acabamento que apresentam em suas características
        maleabilidade e alta durabilidade. Não quebram, não descascam e não amassam. Fácil
        limpeza, mais aderência e firmeza a sua mão, garantindo mais segurança.
        `,
    },
    {
        question: 'POLICARBONATO ACRÍLICO RÍGIDO TRANSPARENTE',
        text: `
        As Capas em Policarbonato Transparente (Acrílicas) são leves, finas e 100 %
        transparentes. Perfeitas para quem quer manter o estilo original do aparelho. Em
        função de sua composição atômica fortemente estruturada forjada sob altas
        temperaturas, apresentam um altíssimo nível de rigidez, proporcionando alta
        resistência a impactos
        `,
    },
];
const impressaoContent = [
    {
        question: 'Quais os cuidados com a minha capinha?',
        text: `Nossas capinhas são fabricadas com o que temos de melhor no mercado de impressão.
        Para que você, cliente, tenha um tempo de vida útil da sua capinha prolongada, siga
        algumas dicas abaixo:
        Evite colocá-la próximo de locais gordurosos.
        Evite colocá-la próximo de lugares com calor excessivo e exposições prolongadas ao sol
        `,
    },
    {
        question: 'Como criar minha capinha?',
        text: `Ao entrar no nosso site, clique no botão “Criar Capinha”. Na ferramenta de criação,
        monte sua capinha do jeito que você quiser. Faça o download de suas fotos ou imagens
        que mais gosta. Finalizando a montagem de sua capinha, clique no botão abaixo
        “Comprar”. Faça seu cadastro em nosso site, siga as instruções do carrinho de compra,
        confira o preço e acompanhe seu pedido. Encaminharemos os dados da sua compra
        para o e-mail ou número de celular cadastrado.
        Se tiver sem ideias ou buscando inspiração, dê uma olhada em nossas capinhas de
        coleções em nosso site ou redes sociais @minhacaseoficial_.
        `,
    },
    {
        question: 'Minha capinha personalizada será exatamente igual a da tela?',
        text: `Sua capinha ficara Linda! A cor que você vê impressa na capa será a mais próxima
        possível da que você vê na sua tela, mas lembre-se sempre que cores sofrem variações
        de monitor para monitor e de acordo com o material que elas estão sendo impressas.
        Imagens que contenham sombras, tendem a ficar mais escuras do que vemos no
        monitor.
        `,
    },
    {
        question: 'Existe variação no posicionamento das imagens?',
        text: `Quando preparar sua arte, certifique-se que os textos e imagens tenham pelo menos 03
        mm de distância das bordas e dos buracos do produto, para evitar que informações
        relevantes e rostos não sejam cortados ou que não apareçam no produto após
        impresso.`,
    },
    {
        question: 'O Logo da MINHACASE ficará exatamente no lugar em que é apresentado na arte no site?',
        text: `Buscamos sempre a melhor posição para o nosso logo não atrapalhar a arte da sua
        capinha. Sendo assim, ele pode variar de posição de acordo com cada modelo de
        aparelho
        `,
    },
    {
        question: 'Tenho garantia do produto?',
        text: `A MINHACASE oferece total qualidade do produto. Caso haja qualquer problema
        com o seu pedido, entre em contato com a gente que iremos solucionar o mais rápido e
        da melhor forma possível.
        Damos aos nossos clientes uma garantia de 90 dias corridos a contar da data do
        recebimento do produto por qualquer defeito de fabricação.
        `,
    },
    {
        question: 'Enviam para todo Brasil?',
        text: `Sim, enviamos para todo o Brasil através dos Correios ou Transportadora.        `,
    },
    {
        question: 'Qual o prazo de produção e entrega?',
        text: `Prazo de produção: Cada produto MINHACASE é personalizado e produzido sob demanda especialmente para você. Nós fazemos de tudo para processar, personalizar e empacotar todos os pedidos em até 3 dias úteis após confirmação de pagamento, que
        pode ser de até 48 horas.
        Prazo de entrega Correios: Frete via SEDEX – Para pedidos feitos por Sedex o prazo é de 1 a 3 dias úteis, para capitais. E o PAC para as capitais e demais cidades são de até 3 a 21 dias úteis.
        O prazo total de entrega é a soma do prazo de produção + o prazo de entrega.
        Exemplo: até 3 dias úteis de produção + 3 dias úteis de entrega = até 6 dias úteis para
        recebimento.
        Prazo de entrega Transportadora: Para compras feitas por transportadora o prazo de entrega varia de acordo com o CEP e ele é informado no seu carrinho.
        O prazo total de entrega é a soma do prazo de produção + o prazo de entrega.
        Exemplo: 3 dias úteis de produção + 3 dias úteis de entrega = até 6 dias úteis para
        recebimento.`,
    },
    {
        question: 'Quais são as formas de pagamento?',
        text: `Cartão de Crédito:
        Aceitamos os cartões de crédito Visa, MasterCard, Diners, Hipercard e Elo, as compras podem ser parceladas em até 10x. Para garantir a sua segurança, contamos com o suporte de empresas especializadas na conferência de dados. Caso haja recusa pela operadora de cartão da solicitação de compra, o pedido será cancelado automaticamente e será enviado um email através do nosso intermediador de pagamento. Portanto, é importante deixá-lo sempre atualizado. Caso isso ocorra,
        verifique o motivo junto a administradora de seu cartão de crédito.
        Obs: Após a finalização de sua compra, as opções de pagamento e parcelamento não poderão ser alteradas.
        Boleto Bancário:Esta forma de pagamento não oferece parcelamento, e tem valor único para pagamento a vista. Caso não haja pagamento do boleto, o pedido será automaticamente cancelado.
        Para realizar o pagamento através de boleto bancário, selecione a opção Boleto Bancário e clique em Confirmar Pagamento, em seguida clique em Gerar Boleto.
        Imprima o boleto bancário e realize o pagamento até a data de vencimento em qualquer agência bancária, pela Internet ou casa lotérica.
        O prazo para confirmação do pagamento é de até 03 (três) dias úteis. O prazo de produção inicia-se após a confirmação do pagamento pela instituição financeira.
        
        
        Débito Online:
        Esta forma de pagamento não oferece parcelamento, e tem valor único parapagamento à vista.
        Será somente aceito débito online pelos bancos: Banco do Brasil, Banrisul, Bradesco e Itaú. O valor máximo para o débito online será estipulado pelo seu banco.
        O pagamento será feito em ambiente seguro do próprio banco e a MINHACASE não terá acesso aos seus dados bancários.
        O prazo de produção informado no fechamento do pedido passa a ser considerado a
        partir do dia útil seguinte à confirmação do pagamento feito pela instituição financeira
        responsável à MINHACASE.
        Importante: antes de realizar compras por débito em conta, desative o bloqueador de
        pop-ups do seu computador. Se ocorrer alguma falha no sistema e você não conseguir
        acessar o internet banking, para o débito em conta, recomendamos que reinicie o
        processo de compra ou altere o meio de pagamento.`,
    },
    {
        question: 'Posso alterar o meu pedido?',
        text: `Você pode solicitar alteração em até 24 horas após a confirmação do seu pagamento.
        Envie um e-mail para sac@minhacase.com.br ou uma mensagem no nosso whatsapp
        (13) 99779-0959 com assunto “ALTERAR MEU PEDIDO + NÚMERO DO PEDIDO”.
        Após esse prazo não conseguimos mais realizar NENHUMA alteração em seu pedido.`,
    },
    {
        question: 'Sou influenciadora (o) e quero fazer uma parceria com a MINHACASE, como faço?',
        text: `Basta você encaminhar uma mensagem para: (13) 99779-0959 com o seu mídia kit que
        vamos analisar o seu perfil.`,
    },
    {
        question: 'Quero cancelar o meu pedido, o que eu faço?',
        text: `Você pode solicitar o cancelamento do seu pedido em até 24 horas após a confirmação
        do seu pagamento. Envie um e-mail para sac@minhacase.com.br ou uma mensagem
        para nosso whatsapp (13) 99779-0959 com assunto “CANCELAMENTO + NÚMERO DO
        PEDIDO”.
        Após esse prazo NÃO é possível cancelar o seu pedido, pois o mesmo já terá
        encaminhado para a produção.`,
    },
    {
        question: 'Meu pedido consta como ENTREGUE mas eu não recebi, o que eu faço?',
        text: `Primeiramente pedimos por gentileza que você entre em contato com as possíveis
        pessoas que possam ter recebido em seu lugar. Porteiro, irmãos, mãe, vizinhos, etc.
        
        Após essa verificação, se ainda assim você não receber o seu pedido, envie um e-mail
        para sac@minhacase.com.br ou uma mensagem para nosso whatsapp (13) 99779-0959
        que por lá vamos analisar o seu caso e te ajudar melhor.`,
    },
    {
        question: 'Não tenho certeza de qual é modelo do seu aparelho, o que eu faço?',
        text: `Para saber qual é o seu modelo, basta você acessar as configurações do seu aparelho e
        confirmar.`,
    },
];

export function AjudaPage() {
    const app = useContext(AppContext);

    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);
    
    const [expanded, setExpanded] = useState<string | false>(false);
    const handleAccChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => setExpanded(isExpanded ? panel : false);;

    const styles = useStyles({
        root: {
            color: app.theme.palette.grey[700],
            padding: '40px',
            display: 'flex',
            flexDirection: 'column',
            gap: '50px',
        },
        pageTitle: {
            textAlign: 'center',
            fontSize: '48px',
            color: app.theme.palette.primary.main,
        },
        sectionTitle: {
            color: app.theme.palette.primary.main,
            fontWeight: '300',
        },
        accordion: {
            margin: '0px !important',
        },
    })();
    return <div className={styles.root}>
        <div className={styles.pageTitle}>
            <span>Ajuda</span>
        </div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Dúvidas Frequentes" {...a11yProps(0)} />
                <Tab label="Troca e devoluções" {...a11yProps(1)} />
                <Tab label="Restituição de Valores" {...a11yProps(2)} />
            </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
            <h3 className={styles.sectionTitle} style={{ marginBottom: '35px' }}>Sobre nossos materiais</h3>

            {materialsContent.map(x => (
            <Accordion expanded={expanded === x.question} onChange={handleAccChange(x.question)} className={styles.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><b>{x.question}</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    {x.text}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            ))}
            
            <h3 className={styles.sectionTitle} style={{ margin: '35px 0px' }}>Sobre a impressão:</h3>
            <p>A impressão UV é uma forma de impressão digital, que utiliza luzes ultra-violeta para secar ou curar a tinta à medida que é impressa, instantaneamente.</p>
            
            {impressaoContent.map(x => (
            <Accordion expanded={expanded === x.question} onChange={handleAccChange(x.question)} className={styles.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><b>{x.question}</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    {x.text}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            ))}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>


            <h3 className={styles.sectionTitle}>Solicitação de troca ou devolução:</h3>
            <p>Para solicitar a troca ou devolução do produto, o consumidor precisa entrar em contato com a nossa equipe através do e-mail: sac@minhacase.com.br informando o número do pedido e motivo da troca ou devolução.
                Condições gerais: O produto deverá ser devolvido nas mesmas condições que quando recebido, e deverá apresentar: etiqueta, embalagem e acessórios originais.
            </p>

            <h3 className={styles.sectionTitle}>Troca/Devolução de Produtos NÃO Personalizados:</h3>
            <p>
                O consumidor tem até 7 (sete) dias corridos após o recebimento do pedido para registrar a solicitação no nosso e-mail sac@minhacase.com.br ou whatsapp (13) 99779-0959.
                Caso receba algum produto diferente do seu pedido: sem acessórios, com a embalagem aberta ou avariada, recuse de imediato. Faça uma ressalva no comprovante de entrega e entre em contato conosco. O produto novo, não personalizado goza de até 7 dias para ser substituído,
                desde que este seja apresentado nas mesmas condições em que foi recebido/compradoconforme abaixo:
                O produto não poderá apresentar QUALQUER indício de uso.
                O produto deve ser devolvido em sua embalagem original, sem violação e acompanhado de todos os acessórios, se houver.
            </p>
            <p>Você poderá fazer a escolha de outro item conforme estoque disponível em nossa loja virtual. A escolha deverá se limitar ao valor do produto; caso exista diferença de preço – para maior, deverá ser providenciado o pagamento da diferença; para menor, a devolução da diferença será efetuada na mesma forma do pagamento de sua compra (ex. Se cartão, a diferença será creditada em seu cartão – o prazo para o crédito ficar disponível será conforme a política da bandeira/operadora de seu cartão; se for via boleto, realizaremos um depósito em conta corrente).
                Em caso de restituição do valor (integral ou parcial) do produto, terá como base o valor do produto que consta na nota fiscal. Exceto frete.
                Para casos de troca de produtos que não apresentem qualquer defeito de fabricação, o consumidor deverá se responsabilizar pelo pagamento do frete relativo ao novo envio de produto diverso.
            </p>
            <p>Nesses casos, o consumidor deverá enviar a mercadoria a ser substituída para a MINHACASE, no endereço constante na Nota Fiscal de compra, através dos correios e com os fretes pertinentes
                devidamente pagos. Recomendamos que seja contratado o serviço de seguro nos casos de devoluções de mercadorias, pois em caso de extravio do produto durante o envio/entrega, a MINHACASE não poderá ser responsabilizada.
                Caso a troca seja devido a um produto recebido em desacordo com o comprado ou por defeito de fabricação, o frete será de responsabilidade da MINHACASE. Entre em contato com nossa equipe pelo e-mail: sac@minhacase.com.br ou pelo nosso whatsapp (13) 99779-0959 para que possamos lhe orientar sobre o procedimento correto para a troca.
            </p>

            <h3 className={styles.sectionTitle}>Troca/Devolução de Produtos Personalizados:</h3>
            <p>Não será efetuada troca/devolução de qualquer produto customizado/personalizado, encomendados e produzidos de acordo com a solicitação e especificação do cliente. A MINHACASE reserva-se o direito de recusar todas as devoluções, trocas ou restituições de valores, pois estes produtos foram concebidos de acordo com a solicitação do cliente,
                impossibilitando a reutilização de componentes já montados/utilizados em uma futura customização/personalização.
            </p>
            <p>Nos casos em que o produto, mesmo que personalizado, apresentar defeito de fabricação, a MINHACASE realizará a devida troca do produto, respeitados os prazos e condições legalmente estabelecidas. Vide nossa Garantia (o prazo de garantia de produtos com defeito é de 90 (noventa) dias corridos a contar da data de recebimento). Caso seja constatada alguma inconsistência na fabricação de seu produto, a MINHACASE irá realizar a troca/substituição de seu produto. Caso o modelo de um dos componentes não esteja mais disponível, iremos oferecer alternativas.
            </p>

            <h3 className={styles.sectionTitle}>Itens de reprovação:</h3>
            <p>
                <ul>
                    <li>Ausência de defeito (não constatação do dano apontado pelo cliente).</li>
                    <li>Indícios de uso inadequado do produto.</li>
                    <li>Indícios de dano acidental.</li>
                    <li>Desgaste natural em decorrência do uso.</li>
                    <li>Lavagem inadequada do produto</li>
                    <li>Compra do modelo não correspondente ao seu aparelho</li>
                </ul>
            </p>
            <p>Visando a sua total satisfação, a MINHACASE possui uma Politica de Troca e Devolução alinhada
                às mais recentes normas do Código de Defesa do Consumidor.
            </p>

            <h3 className={styles.sectionTitle}>O fornecedor é OBRIGADO a trocar um produto que não apresenta vício?</h3>
            <p>Não. A troca de produtos não viciados é uma opção do fornecedor. Porém, se o consumidor for informado pelo fornecedor que existe a possibilidade da troca de produto, nestas condições, será obrigado a fazer.
                Assim, se o fornecedor optar por trocar produtos não viciados pode definir as condições para realizá-la, mas deve sempre informar clara e previamente essas condições ao consumidor.(Artigo 30, do Código de Defesa do Consumidor ).
            </p>

            <h3 className={styles.sectionTitle}>Quando o fornecedor trabalha com PRODUTOS SOB ENCOMENDA, é obrigado a aceitar a desistência do consumidor?</h3>
            <p>O Código de Defesa do Consumidor não obriga o fornecedor a aceitar a desistência após a realização do negócio. Importante que o consumidor seja informado sobre a encomenda e o prazo para entrega. Cabe ao fornecedor cumprir a oferta, caso contrário, o consumidor poderá residir o contrato com direito à restituição da quantia paga, nos termos do artigo 35, III do CDC.
                Atenção: a MINHACASE isenta-se da obrigação de atender às solicitações de troca ou devolução de qualquer produto devolvido sem comunicação, fora do prazo ou com ausência de itens/acessórios que o acompanham.
                Ressaltamos que cada empresa tem a sua política de trocas/devoluções. A MINHACASE reservase o direito de NÃO realizar a troca de produtos personalizados, encomendados e produzidos de acordo com a solicitação e especificação do cliente, que não apresentem defeitos de
                fabricação. Sendo assim, produtos personalizados com fotos ou nome na estampa, só serão trocados, caso seja constatado defeito de fabricação pelo nosso setor de qualidade.
            </p>

            <h3 className={styles.sectionTitle}>Logística Reversa:</h3>
            <p>É um serviço de remessa de mercadorias em devolução, sem ônus ao destinatário, para serem
                entregues exclusivamente no endereço indicado pelo cliente.
                O código de postagem da logística reversa, só é disponibilizado quando o produto apresentar
                defeito de fabricação e o mesmo for constatado pelo nosso setor responsável.
                Sendo assim, a nossa Equipe analisa caso a caso e indica qual o caso será necessário à logística
                reversa. Ressaltamos que, não é obrigatório disponibilizar o serviço de logística reversa em
                casos onde não seja constatado defeito no produto.</p>

        </TabPanel>
        <TabPanel value={tabValue} index={2}>
            <h3 className={styles.sectionTitle}>Restituição de Valores:</h3>
            <p>Cartão de Crédito: No caso de compra efetuada com cartão de crédito, a devolução do valor será feita por meio de estorno no cartão de crédito em até 10 (dez) dias úteis após o recebimento do produto na sede da MINHACASE. Após esse prazo, consulte a administradora responsável para saber como será lançado o estorno em sua fatura.
            </p>
            <p>Boleto Bancário: As compras por boleto serão feitas do através do IUGU e a devolução de valores pagos em boleto bancário ocorrerá por meio de reembolso na conta corrente do titular da compra em até 10 (dez) dias úteis após o recebimento do produto na sede da MINHACASE.
                Caso o titular da compra não possua conta corrente, o cliente receberá um vale compras no valor do produto. Não serão efetuados depósitos em contas de terceiros.
                Atenção: A restituição dos valores será processada somente após o recebimento e análise das condições do(s) produto(s) em nosso centro de distribuição, conforme as condições gerais descritas no início.
            </p>

            <h3 className={styles.sectionTitle}>Promoção</h3>
            <p>Para produtos adquiridos em promoção, caso seja necessária a troca e / ou devolução, o valor considerado será o pago pelo produto e não o seu valor original.
            </p>
            <p><b>Exemplo:</b> Se você adquiriu um item em promoção por R$ 50,00, mas seu valor original era de R$ 100,00, o valor considerado será o de R$50,00.</p>
        </TabPanel>
    </div>;
}

function a11yProps(index: number) {
    return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`, };
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
