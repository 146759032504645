import { Button, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { AppContext } from "../app.context";
import { FormField, useForm } from "../components";
import { Route } from "../contants";
import { Address } from "../models";
import {
  getAddressByZip,
  getCartCount,
  httpGet,
  useNavigation,
  useStyles,
} from "../services";

const addressId = "mcs-address";
const complId = "mcs-compl";

export function AddressPage() {
  const app = useContext(AppContext);
  const { navigate } = useNavigation();

  const [hasLogradouro, setHasLogradouro] = useState(true);
  const form = useForm<Address>(app.address || ({} as any), {
    zipCode: {
      type: "text",
      label: "CEP",
      required: true,
      regex: /\d{5}-\d{3}/g,
    },
    address1: { type: "text", label: "Endereço", required: true, minLength: 5 },
    address2: { type: "text", label: "Complemento", required: true },
    city: { type: "text", label: "Cidade", required: true },
    state: {
      type: "text",
      label: "Estado",
      required: true,
      regex: /^[A-Z]{2}$/g,
    },
  });
  const address = form.values;

  useEffect(() => {
    if (!getCartCount()) navigate(Route.Home);
  });

  const styles = useStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
    },
    pageTitle: {
      textAlign: "center",
      fontSize: "48px",
      color: app.theme.palette.primary.main,
    },
  })();

  const validate = async () => {
    app.setLoading(true);
    try {
      setHasLogradouro(true);
      const validatedAddress = (await getAddressByZip(address.zipCode!)) as any;
      if (validatedAddress.erro) {
        app.setLoading(false);
        app.notification("error", "CEP inválido");
        return;
      }

      form.set({
        address1: validatedAddress.logradouro,
        city: validatedAddress.localidade,
        state: validatedAddress.uf,
        zipCode: address.zipCode,
      });
      setHasLogradouro(Boolean(validatedAddress.logradouro));
      if (Boolean(validatedAddress.logradouro))
        //@ts-ignore
        document.querySelector(`#${complId}`)!.focus();
      //@ts-ignore
      else document.querySelector(`#${addressId}`)!.focus();
    } catch (error) {
      console.error(error);
      app.notification("error", "Algo deu errado. Contate o administrator.");
    }
    app.setLoading(false);
  };

  return (
    <div className={styles.root}>
      <div className={styles.pageTitle}>
        <span>Endereço de Entrega</span>
      </div>
      <Grid container spacing={3}>
        <Grid item sm={4}>
          <FormField form={form} field="zipCode" mask="00000-000" />
        </Grid>
        <Grid item sm={6}>
          <Button
            variant="contained"
            onClick={validate}
            disabled={!form.validateField("zipCode").valid}
          >
            Validar CEP
          </Button>
        </Grid>
        <Grid item xs={12} sm={8}>
          <FormField
            form={form}
            field="address1"
            id={addressId}
            disabled={hasLogradouro}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField form={form} field="address2" id={complId} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField form={form} field="city" disabled={true} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField form={form} field="state" disabled={true} />
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button variant="outlined" onClick={() => navigate(Route.Checkout)}>
          Voltar
        </Button>
        <Button
          variant="contained"
          disabled={!form.isValid()}
          onClick={() => {
            app.setAddress(address!);
            navigate(Route.Review);
          }}
        >
          Próximo
        </Button>
      </div>
    </div>
  );
}
