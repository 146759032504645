import { Button, Divider, Stack } from '@mui/material';
import { useContext } from 'react';
import { flatten } from 'lodash';

import { AppContext } from '../app.context';
import { phonesConfig, Route } from '../contants';
import { decimalString, deleteFileFromServer, getCartItems, getCartTotal, removeCartItem, useNavigation, useStyles } from '../services';

export function CheckoutPage() {
    const app = useContext(AppContext);
    const { navigate } = useNavigation();

    const styles = useStyles({
        root: {
            padding: '15px 0px',
        },
        row: {
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        imgWrapper: {
            height: (data: any) => data.small ? 'auto' : '200px',
            width: (data: any) => data.small ? 'auto' : '200px',
            textAlign: 'center'
        },
        nameWrapper: {
            flexGrow: 1,
        },
        name: {
            fontSize: (data: any) => data.small ? '14px' : '24px'
        },
        description: {
        },
        btnWrapper: {
            display: 'flex',
            gap: '15px',
        },
        price: {
            color: app.theme.palette.primary.main,
            minWidth: (data: any) => data.small ? 'auto' : '100px',
        },
        totalWrapper: {
            display: 'flex',
            gap: '15px',
        },
        totalLabel: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row-reverse',
            paddingRight: '40px'
        },
        totalCel: {
            color: app.theme.palette.primary.main,
            minWidth: '100px',
        },
    })({
        small: app.xxs || app.xs
    });

    const items = getCartItems();

    return (
        <div className={styles.root}>
            {items.length <= 0 ?
                <div>Nenhum produto adicionado ao carrinho</div>
                :
                <Stack spacing={5}>
                    {items.map((item, i) => <div className={styles.row}>
                        <div className={styles.imgWrapper}>
                            <img src={item.imageUrl}  alt=""
                                height={app.xxs || app.xs ? '120px' : '100%'}
                            />
                        </div>
                        <div className={styles.nameWrapper}>
                            <Stack spacing={1}>
                                <div className={styles.name}>{item.name}</div>
                                {item.description &&
                                app.xxs && app.xs && app.sm &&
                                    <div style={{ margin: '0px' }} className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />
                                }
                                {item.brand &&
                                    <div><b>Marca: </b>{phonesConfig.find(x => x.brand === item.brand)?.label}</div>
                                }
                                {item.model &&
                                    <div><b>Modelo: </b>{flatten(phonesConfig.map(x => x.models)).find(x => x.value === item.model)?.name}</div>
                                }
                                <div className={styles.btnWrapper}>
                                    <Button variant='text' color='error'
                                        onClick={() => {
                                            removeCartItem(i);
                                            if (item.type === 'customized') {
                                                deleteFileFromServer(item.path!);
                                            }
                                            app.reRenderApp();
                                        }}
                                    >
                                        Remover
                                    </Button>
                                </div>
                            </Stack>
                        </div>
                        <div className={styles.price}>
                            R$ {decimalString(item.price)}
                        </div>
                    </div>)}
                    <Divider />
                    <div className={styles.totalWrapper}>
                        <div className={styles.totalLabel}>Total</div>
                        <div className={styles.totalCel}>R$ {getCartTotal().toFixed(2)}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        {
                            app.user ?
                                <Button variant='contained'
                                onClick={() => navigate(Route.Address, {}, true)}
                                >
                                    Finalizar Compra
                                </Button>
                                :
                                <Button variant='contained' disabled={true}>Faça login para finalizar a compra</Button>
                        }
                        {/* <Button variant='contained' disabled={true}>Site em construcao</Button> */}
                    </div>
                </Stack>
            }
        </div>
    );
}