import { filter } from "lodash";
import { useContext } from "react";
import { AppContext } from "../app.context";
import { useStyles } from "../services";

export function useCommonStyles() {

    const app = useContext(AppContext);

    return useStyles({
        customScrollBar: {
            scrollbarColor: `${app.theme.palette.grey[400]} !important`,
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': { width: '18px !important', height: '18px !important', },
            '&::-webkit-scrollbar-thumb': {
                border: '6px solid transparent !important',
                background: `${app.theme.palette.grey[400]} !important`,
                borderRadius: '10px !important',
                backgroundClip: 'padding-box !important',
            },
            '&::-webkit-scrollbar-corner': { background: 'transparent !important', },
            '&::-webkit-scrollbar-thumb:vertical': { minHeight: '30px !important', },
            '&::-webkit-scrollbar-thumb:horizontal': { minWidth: '30px !important', },
            '&::-webkit-scrollbar-thumb:hover': {
                background: `${app.theme.palette.grey[500]} !important`,
                backgroundClip: 'padding-box !important',
                border: '4px solid transparent !important',
            },
            '&::-webkit-scrollbar-track': { background: 'transparent !important', },
        },
    })();
}