import {
  Paper,
  Modal,
  Grid,
  Button,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { flatten } from "lodash";

import { AppContext } from "../app.context";
import { Categories, phonesConfig } from "../contants";
import { addToCart, decimalString, useStyles, useWordpress } from "../services";

interface ModalProductDetailProps {
  order: any;
  open: boolean;
  onClose: () => void;
}

export function ModalOrder(props: ModalProductDetailProps) {
  const { order } = props;
  const app = useContext(AppContext);
  const { getOrderProducts } = useWordpress();

  const orderLines = props.order.line_items;

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<any[]>([]);

  useEffect(() => {
    const loadData = async () => {
      const data = await getOrderProducts(props.order);
      setProducts(data);
      setLoading(false);
    };
    if (loading) loadData();
  }, [loading]);

  const styles = useStyles({
    root: {
      width: "75%",
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      minWidth: "800px",
      padding: "20px",
    },
    pageTitle: {
      textAlign: "center",
      color: app.theme.palette.primary.main,
    },
    pageSubtitle: {
      textAlign: "center",
      color: app.theme.palette.primary.main,
    },
    img: {
      height: "400px",
    },

    row: {
      display: "flex",
      gap: "20px",
      alignItems: "center",
      justifyContent: "space-between",
    },
    imgWrapper: {
      height: "120px",
      width: "120px",
      textAlign: "center",
    },
    nameWrapper: {
      flexGrow: 1,
    },
    name: {
      fontSize: "18px",
    },
    price: {
      color: app.theme.palette.primary.main,
      minWidth: "100px",
    },
  })();

  return (
    <Modal open={props.open} onClose={() => props.onClose()}>
      <Paper className={styles.root} elevation={6}>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <h2 className={styles.pageTitle}>Pedido #{order.id}</h2>
          </Grid>
          <Grid item sm={8}>
            <h3 className={styles.pageTitle}>Itens</h3>
            {loading ? (
              <CircularProgress />
            ) : (
              <Stack spacing={2}>
                {order.line_items.map((item: any, i: number) => (
                  <div className={styles.row}>
                    <div className={styles.imgWrapper}>
                      {(() => {
                        const currProd = products.find(
                          (x) => x.id === item.product_id
                        );
                        return currProd ? (
                          <img
                            src={currProd.images[0].src}
                            height="100%"
                            alt=""
                          />
                        ) : (
                          <></>
                        );
                      })()}
                    </div>
                    <div className={styles.nameWrapper}>
                      <Stack spacing={1}>
                        <div className={styles.name}>
                          {(() => {
                            const currProd = products.find(
                              (x) => x.id === item.product_id
                            );
                            return currProd ? currProd.name : "";
                          })()}
                        </div>
                      </Stack>
                    </div>
                    <div className={styles.price}>
                      R$ {decimalString(Number(item.total))}
                    </div>
                  </div>
                ))}
              </Stack>
            )}
          </Grid>
          <Grid item sm={4}>
            <h3 className={styles.pageTitle}>Endereço</h3>
            <div>
              <div>
                <span>{order.shipping.address_1}, </span>
                <span>{order.shipping.address_2} - </span>
                <span>{order.shipping.postcode}</span>
              </div>
              <div style={{ marginTop: "10px" }}>
                <span>{order.shipping.city} - </span>
                <span>{order.shipping.state}</span>
              </div>
            </div>
          </Grid>
          {/* <Grid item sm={6}>
            <img className={styles.img} alt="" src={product.images[0].src} />
          </Grid>
          <Grid item sm={6}>
            <Stack spacing={4}>
              <h2>{product.name}</h2>
              <div
                style={{ marginTop: "0px" }}
                dangerouslySetInnerHTML={{ __html: product.description }}
              ></div>
            </Stack>
          </Grid> */}
        </Grid>
      </Paper>
    </Modal>
  );
}
