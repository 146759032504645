import { imageUrl, isLocal, useNavigation, useStyles } from '../services';
import { Button, Grid, Link, TextField, useMediaQuery } from "@mui/material";
import {
  Email as EmailIcon,
  LocalPhone as LocalPhoneIcon,
  WorkHistory as TimeIcon,
} from '@mui/icons-material';
import { useContext } from 'react';
import { AppContext } from '../app.context';
import { Route } from '../contants';

import { FormField, useForm } from './form';
import json2mq from 'json2mq';

const relativePath = (isLocal() ? '' : '/wp-content/reactpress/apps/minha-case/build') + '/assets/img/logos/';

export function Footer() {
  const app = useContext(AppContext);
  const { navigate } = useNavigation();

  const form = useForm<{ email: string }>({} as any,
    {
      email: { type: 'text', label: '', required: true, regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ }
    });

  const styles = useStyles({
    root: {

    },
    topSection: {
      backgroundColor: app.theme.palette.secondary.main,
      padding: (data: any) => data.small ? '15px' : '60px',
      borderBottom: '5px solid ' + app.theme.palette.secondary.dark,
    },
    receba: {
      color: app.theme.palette.primary.main,
      fontSize: '30px',
    },
    recebaText: {

    },
    bottomSection: {
      color: app.theme.palette.grey[700],
    },
    column: {
      display: 'flex',
      flexDirection: 'column !important',
      gap: '13px',
    },
    columnHeader: {
      fontSize: '18px',
      fontWeight: '600',
    },
    atendimentoItem: {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
    },
  })({
    small: app.xxs || app.xs
  });

  return <div className={styles.root}>
    <div className={styles.topSection}>
      <Grid container spacing={4}
        columns={24}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item sm={24} md={12}>
          <h3 className={styles.receba}>Receba as novidades</h3>
          <p className={styles.recebaText}>Te avisaremos em primeira mão as novidades de capinhas e produtos aqui da MinhaCase</p>
        </Grid>

        <Grid item sm={24} md={12} style={{ display: 'flex' }}>
          <FormField form={form} field='email'
            placeholder='Entre seu email aqui'
            style={{ flexGrow: 10 }}
            inputStyle={{ backgroundColor: 'white' }}
          />
          <Button variant="contained" style={{ flexGrow: 5, height: '56px' }}
            disabled={!form.isValid()}
            onClick={() => form.reset()}
          >Quero Saber</Button>
        </Grid>

      </Grid>
    </div>
    <div className={styles.bottomSection}>
      <Grid
        container
        columns={12}
        justifyContent="space-evenly"
        alignItems='flex-start' padding='70px 0px'
      >
        {
          !app.xxs && !app.xs ?
            <Grid item>
              <img width={120} height={120} src={imageUrl('static/logos/logo')} alt="Minha Case logo" />
            </Grid>
            :
            <></>
        }
        <Grid item className={styles.column}>
          <span className={styles.columnHeader}>ATENDIMENTO AO CLIENTE</span>
          <div className={styles.atendimentoItem}>
            <EmailIcon /> <a href='mailto:sac@minhacase.com.br'>sac@minhacase.com.br</a>
          </div>
          <div className={styles.atendimentoItem}>
            <LocalPhoneIcon /> (13) 99779-0959
          </div>
          <div className={styles.atendimentoItem}>
            <TimeIcon /> Segunda à Sexta Feira das 9:00h<br />às 17:00h
          </div>
        </Grid>
        {
          !app.xxs ?
            <Grid item className={styles.column} alignItems='flex-start'>
              <span className={styles.columnHeader}>PRECISA DE AJUDA</span>
              <Button variant="text"
                style={{ padding: '0px' }}
                onClick={() => navigate(Route.Ajuda, {}, true)}
              >Central de Ajuda</Button>
            </Grid>
            :
            <></>
        }
        {
          app.md || app.lg || app.xl ?
            <>
              <Grid item className={styles.column}>
                <span className={styles.columnHeader}>SIGA-NOS</span>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <img width={30} height={30} src={imageUrl('static/logos/facebook')} alt="facebook logo"
                    style={{ cursor: 'pointer' }}
                    onClick={() => { window.open('https://www.instagram.com/minhacaseoficial_/', '#blank') }}
                  />
                  <img width={30} height={30} src={imageUrl('static/logos/instagram')} alt="instagram logo"
                    style={{ cursor: 'pointer' }}
                    onClick={() => { window.open('https://www.instagram.com/minhacaseoficial_/', '#blank') }}
                  />
                </div>
              </Grid>
              <Grid item className={styles.column}>
                <span className={styles.columnHeader}>SITE E PAGAMENTO SEGURO</span>
                <img width={320} height={100} src={imageUrl('static/logos/payments')} alt="Minha Case logo" />
              </Grid>
            </>
            :
            <></>
        }
      </Grid>
    </div>
  </div>;
}
