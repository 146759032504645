import { useContext, useEffect, useState } from 'react';
import { Button, Grid, Stack } from '@mui/material';
import html2canvas from 'html2canvas';

import { AppContext } from '../app.context';
import { addToCart, httpGet, imageUrl, isLocal, showStepper, sleep, uploadFileToServer, useNavigation, useStyles } from '../services';
import { ImageContainer, StepBaseProps } from '.';
import { ProductLayout, step3Complete } from '../models';
import { Route, Routes } from '../contants';

interface StepProps extends StepBaseProps {

}

const layoutsRelativePath = (isLocal() ? '' : '/wp-content/reactpress/apps/minha-case/build') + '/assets/img/layouts/';
const casesRelativePath = (isLocal() ? '' : '/wp-content/reactpress/apps/minha-case/build') + '/assets/img/cases/';

const imgHeight = 420;
const imgWidth = 210;

const containerClass = 'mcs-image-container';

const layoutOpts = [
  { value: ProductLayout.Full, img: 'layout-full', },
  { value: ProductLayout.FiftyFifty, img: 'layout-fifty-fifty', },
  { value: ProductLayout.Three, img: 'layout-three', },
  { value: ProductLayout.Four, img: 'layout-four', },
  { value: ProductLayout.Six, img: 'layout-six', },
  { value: ProductLayout.LargeTopTwoBottom, img: 'layout-large-top-two-bottom', },
  { value: ProductLayout.FourTopLargeBottom, img: 'layout-four-top-large-bottom', },
];

export function Step3Imagens(props: StepProps) {
  const app = useContext(AppContext);
  const { navigate } = useNavigation();
  const { product } = props;

  const small = app.xxs || app.xs || app.sm || app.md;

  const [loading, setLoading] = useState(false);
  const [modelImg, setModelImg] = useState('');
  const [borderImg, setBorderImg] = useState('');
  const [imgs, setImgs] = useState<string[]>([]);
  const [layoutSelected, setLayoutSelected] = useState<ProductLayout | undefined>(product.layout);

  const imgPath = `${product.brand}_${product.model}`;
  const imgBorderPath = `${product.brand}_${product.model}_border`;

  const styles = useStyles({
    root: {
      margin: (data: any) => data.small ? '-10px 0px' : '-10px -80px',
    },
    imgsWrapper: {
      position: 'relative',
    },
    borderImg: {
      position: 'absolute',
      left: 0,
      zIndex: 100,
    },
    layoutsWrapper: {
      display: 'flex',
      gap: '5px',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
    },
    layoutBtn: {
      padding: '30px'
    },


    fullImg: {
      top: '0',
      height: `${imgHeight}px`,
      width: `${imgWidth}px`,
    },


    fiftyImg1: {
      top: '0',
      height: `${imgHeight / 2}px`,
      width: `${imgWidth}px`,
      borderTop: '0px !important',
      borderLeft: '0px !important',
      borderRight: '0px !important',
    },
    fiftyImg2: {
      top: `${imgHeight / 2}px`,
      height: `${imgHeight / 2}px`,
      width: `${imgWidth}px`,
      borderBottom: '0px !important',
      borderLeft: '0px !important',
      borderRight: '0px !important',
    },


    threeImg1: {
      top: '0',
      height: `${imgHeight / 3}px`,
      width: `${imgWidth}px`,
      borderTop: '0px !important',
      borderLeft: '0px !important',
      borderRight: '0px !important',
    },
    threeImg2: {
      top: `${imgHeight / 3}px`,
      height: `${imgHeight / 3}px`,
      width: `${imgWidth}px`,
      borderLeft: '0px !important',
      borderRight: '0px !important',
    },
    threeImg3: {
      top: `${(imgHeight / 3) * 2}px`,
      height: `${imgHeight / 3}px`,
      width: `${imgWidth}px`,
      borderBottom: '0px !important',
      borderLeft: '0px !important',
      borderRight: '0px !important',
    },


    fourImg1: {
      top: '0',
      height: `${imgHeight / 2}px`,
      width: `${imgWidth / 2}px`,
      borderTop: '0px !important',
      borderLeft: '0px !important',
    },
    fourImg2: {
      top: '0',
      left: `${imgWidth / 2}px`,
      height: `${imgHeight / 2}px`,
      width: `${imgWidth / 2}px`,
      borderTop: '0px !important',
      borderRight: '0px !important',
    },
    fourImg3: {
      top: `${imgHeight / 2}px`,
      left: '0',
      height: `${imgHeight / 2}px`,
      width: `${imgWidth / 2}px`,
      borderBottom: '0px !important',
      borderLeft: '0px !important',
    },
    fourImg4: {
      top: `${imgHeight / 2}px`,
      left: `${imgWidth / 2}px`,
      height: `${imgHeight / 2}px`,
      width: `${imgWidth / 2}px`,
      borderBottom: '0px !important',
      borderRight: '0px !important',
    },


    sixImg1: {
      top: '0px',
      left: '0px',
      height: `${imgHeight / 3}px`,
      width: `${imgWidth / 2}px`,
      borderTop: '0px !important',
      borderLeft: '0px !important',
    },
    sixImg2: {
      top: '0px',
      left: `${imgWidth / 2}px`,
      height: `${imgHeight / 3}px`,
      width: `${imgWidth / 2}px`,
      borderTop: '0px !important',
      borderRight: '0px !important',
    },
    sixImg3: {
      top: `${imgHeight / 3}px`,
      left: '0px',
      height: `${imgHeight / 3}px`,
      width: `${imgWidth / 2}px`,
      borderLeft: '0px !important',
      borderBottom: '0px !important',
      borderTop: '0px !important',
    },
    sixImg4: {
      top: `${imgHeight / 3}px`,
      left: `${imgWidth / 2}px`,
      height: `${imgHeight / 3}px`,
      width: `${imgWidth / 2}px`,
      borderRight: '0px !important',
      borderBottom: '0px !important',
      borderTop: '0px !important',
    },
    sixImg5: {
      top: `${(imgHeight / 3) * 2}px`,
      left: '0px',
      height: `${imgHeight / 3}px`,
      width: `${imgWidth / 2}px`,
      borderBottom: '0px !important',
      borderLeft: '0px !important',
    },
    sixImg6: {
      top: `${(imgHeight / 3) * 2}px`,
      left: `${imgWidth / 2}px`,
      height: `${imgHeight / 3}px`,
      width: `${imgWidth / 2}px`,
      borderBottom: '0px !important',
      borderRight: '0px !important',
    },


    largeTopTwoBottomImg1: {
      top: '0px',
      left: '0px',
      height: `${imgHeight / 2}px`,
      width: `${imgWidth}px`,
      borderTop: '0px !important',
      borderLeft: '0px !important',
      borderRight: '0px !important',
    },
    largeTopTwoBottomImg2: {
      top: `${imgHeight / 2}px`,
      left: '0px',
      height: `${imgHeight / 2}px`,
      width: `${imgWidth / 2}px`,
      borderTop: '0px !important',
      borderBottom: '0px !important',
      borderLeft: '0px !important',
    },
    largeTopTwoBottomImg3: {
      top: `${imgHeight / 2}px`,
      left: `${imgWidth / 2}px`,
      height: `${imgHeight / 2}px`,
      width: `${imgWidth / 2}px`,
      borderTop: '0px !important',
      borderBottom: '0px !important',
      borderRight: '0px !important',
    },


    fourTopLargeBottomImg1: {
      top: '0px',
      left: '0px',
      height: `${imgHeight / 3}px`,
      width: `${imgWidth / 2}px`,
      borderTop: '0px !important',
      borderLeft: '0px !important',
    },
    fourTopLargeBottomImg2: {
      top: '0px',
      left: `${imgWidth / 2}px`,
      height: `${imgHeight / 3}px`,
      width: `${imgWidth / 2}px`,
      borderTop: '0px !important',
      borderRight: '0px !important',
    },
    fourTopLargeBottomImg3: {
      top: `${imgHeight / 3}px`,
      left: '0px',
      height: `${imgHeight / 3}px`,
      width: `${imgWidth / 2}px`,
      borderTop: '0px !important',
      borderLeft: '0px !important',
    },
    fourTopLargeBottomImg4: {
      top: `${imgHeight / 3}px`,
      left: `${imgWidth / 2}px`,
      height: `${imgHeight / 3}px`,
      width: `${imgWidth / 2}px`,
      borderTop: '0px !important',
      borderRight: '0px !important',
    },
    fourTopLargeBottomImg5: {
      top: `${(imgHeight / 3) * 2}px`,
      left: '0px',
      height: `${imgHeight / 3}px`,
      width: `${imgWidth}px`,
      borderTop: '0px !important',
      borderBottom: '0px !important',
      borderLeft: '0px !important',
      borderRight: '0px !important',
    },

    btnsWrapper: {
      display: 'flex',
      justifyContent: 'space-between'
    },

    imgUploadBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '3px dashed grey',
      height: '80px',
      cursor: 'pointer',
    },
    imgsBox: {
      display: 'flex',
      gap: '10px',
    },
    imageItemWrapper: {
    },
    imageItem: {
      height: '90px',
      width: '90px',
      borderRadius: '50%',
    },
  })({
    small
  });

  const phoneWrapper = <div className={styles.imgsWrapper} id='mcs-phone-container'>
    <img height={`${imgHeight}px`}
      src={`/assets/img/cases/${imgPath}.png`}
      alt="canvas"
    />
    <img className={styles.borderImg}
      height={`${imgHeight}px`}
      src={`/assets/img/cases/${imgBorderPath}.png`}
      alt="canvas"
    />
    {
      product.layout === ProductLayout.Full ?
        <>
          <ImageContainer id='' className={`${styles.fullImg} ${containerClass}`} field='img1'
            product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
          />
        </>
        :
        product.layout === ProductLayout.FiftyFifty ?
          <>
            <ImageContainer id='' className={`${styles.fiftyImg1} ${containerClass}`} field='img1'
              product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
            />
            <ImageContainer id='' className={`${styles.fiftyImg2} ${containerClass}`} field='img2'
              product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
            />
          </>
          :
          product.layout === ProductLayout.Three ?
            <>
              <ImageContainer id='1' className={`${styles.threeImg1} ${containerClass}`} field='img1'
                product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
              />
              <ImageContainer id='2' className={`${styles.threeImg2} ${containerClass}`} field='img2'
                product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
              />
              <ImageContainer id='3' className={`${styles.threeImg3} ${containerClass}`} field='img3'
                product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
              />
            </>
            :
            product.layout === ProductLayout.Four ?
              <>
                <ImageContainer id='' className={`${styles.fourImg1} ${containerClass}`} field='img1'
                  product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                />
                <ImageContainer id='' className={`${styles.fourImg2} ${containerClass}`} field='img2'
                  product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                />
                <ImageContainer id='' className={`${styles.fourImg3} ${containerClass}`} field='img3'
                  product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                />
                <ImageContainer id='' className={`${styles.fourImg4} ${containerClass}`} field='img4'
                  product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                />
              </>
              :
              product.layout === ProductLayout.Six ?
                <>
                  <ImageContainer id='' className={`${styles.sixImg1} ${containerClass}`} field='img1'
                    product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                  />
                  <ImageContainer id='' className={`${styles.sixImg2} ${containerClass}`} field='img2'
                    product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                  />
                  <ImageContainer id='' className={`${styles.sixImg3} ${containerClass}`} field='img3'
                    product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                  />
                  <ImageContainer id='' className={`${styles.sixImg4} ${containerClass}`} field='img4'
                    product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                  />
                  <ImageContainer id='' className={`${styles.sixImg5} ${containerClass}`} field='img5'
                    product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                  />
                  <ImageContainer id='' className={`${styles.sixImg6} ${containerClass}`} field='img6'
                    product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                  />
                </>
                :
                product.layout === ProductLayout.LargeTopTwoBottom ?
                  <>
                    <ImageContainer id='' className={`${styles.largeTopTwoBottomImg1} ${containerClass}`} field='img1'
                      product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                    />
                    <ImageContainer id='' className={`${styles.largeTopTwoBottomImg2} ${containerClass}`} field='img2'
                      product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                    />
                    <ImageContainer id='' className={`${styles.largeTopTwoBottomImg3} ${containerClass}`} field='img3'
                      product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                    />
                  </>
                  :
                  product.layout === ProductLayout.FourTopLargeBottom ?
                    <>
                      <ImageContainer id='' className={`${styles.fourTopLargeBottomImg1} ${containerClass}`} field='img1'
                        product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                      />
                      <ImageContainer id='' className={`${styles.fourTopLargeBottomImg2} ${containerClass}`} field='img2'
                        product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                      />
                      <ImageContainer id='' className={`${styles.fourTopLargeBottomImg3} ${containerClass}`} field='img3'
                        product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                      />
                      <ImageContainer id='' className={`${styles.fourTopLargeBottomImg4} ${containerClass}`} field='img4'
                        product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                      />
                      <ImageContainer id='' className={`${styles.fourTopLargeBottomImg5} ${containerClass}`} field='img5'
                        product={product} onProductChanged={(newProd) => props.onProductChanged(newProd)}
                      />
                    </>
                    :
                    null
    }
  </div>;
  const layoutPicker = <div className={styles.layoutsWrapper}>
    {layoutOpts.map(x => (
      <Button className={styles.layoutBtn}
        key={x.value}
        style={{ ...layoutSelected !== x.value ? { border: '1px solid rgba(0, 0, 0, 0)' } : null }}
        variant={layoutSelected === x.value ? 'outlined' : 'text'}
        onClick={async () => {
          if (layoutSelected === x.value) {
            product.layout = undefined;
            setLayoutSelected(undefined);
          }
          else {
            product.layout = x.value;
            setLayoutSelected(x.value);
          }
          props.onProductChanged(product);

          if (small) {
            sleep(50);
            const finishBtn = document.querySelector(`.${styles.imgsWrapper}`);
            if (finishBtn) finishBtn.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }}
      >
        <img height='60px' width='35px'
          src={imageUrl(`static/layouts/${x.img}`)}
          alt={`logo`}
        />
      </Button>
    ))}
  </div>;
  const btnsWrapper = <div className={styles.btnsWrapper}>
    <Button disabled={loading} variant='contained'
      onClick={() => { if (props.onPreviousStep) props.onPreviousStep(); showStepper();}}>Voltar</Button>
    <Button disabled={!step3Complete(product) || loading} variant='contained'
      onClick={async () => {
        try {
          app.setLoading(true);
          document.querySelectorAll(`.${containerClass}`).forEach((x: any) => x.style.borderStyle = 'none');
  
          const canvas = await html2canvas(document.querySelector(`.${styles.imgsWrapper}`)!, {
            backgroundColor: 'rgba(0,0,0,0)',
          });
  
          const fileName = `custom-product-${new Date().toJSON()}.png`;
          canvas.toBlob(async (blob) => {
            try {
              const result = await uploadFileToServer(fileName, blob!);
              const { ref } = result;
              const { bucket, fullPath } = ref;
              product.imageUrl = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${fullPath}?alt=media`;
              product.bucket = bucket;
              product.path = fullPath;

              // Object.keys(product.images).forEach(key => {
              //   const base64 = product.images[key];

              //   const imgContent = base64.replace(/data:(.*);base64,/g, '');
              //   const fileType = base64.split(',')[0].split(':')[1].split(';')[0];

              //   const byteCharacters = atob(imgContent);
              //   const byteNumbers = new Array(byteCharacters.length);
              //   for (let i = 0; i < byteCharacters.length; i++) {
              //       byteNumbers[i] = byteCharacters.charCodeAt(i);
              //   }
              //   const byteArray = new Uint8Array(byteNumbers);
              //   const blob = new Blob([byteArray], {type: fileType});
              // });
              Object.keys(product.images).forEach(x => delete product.images[x]);
              
              addToCart(product);
              navigate(Route.Home, {}, true);
              app.setLoading(false);
              app.notification('success', 'Produto adicionado ao carrinho!');

            } catch (error) {
              app.setLoading(false);
              app.notification('error', `ERRO: ${JSON.stringify(error)}`);
            }
          });
        } catch (error) {
          app.setLoading(false);
          app.notification('error', 'Ocorreu um erro, por favor tente novamente');
        }

      }}>Adicionar ao Carrinho</Button>
  </div>;

  return (
    <div className={styles.root}>
      {
        small ?
          <Stack spacing={4}>
            <Grid container justifyContent={'center'} spacing={2}>
              <Grid item xs={12} >
                <Stack spacing={2}>
                  <h2>Selecione o layout da sua capinha</h2>
                  {layoutPicker}
                </Stack>
              </Grid>
              {layoutSelected &&
              <Grid item xs={12} justifyContent='center'>
                <h3>Click no bloco que deseja adicionar cada imagem</h3>
              </Grid>
              }
              <Grid item xs={12} display='flex' justifyContent='center'>
                {phoneWrapper}
              </Grid>
            </Grid>
            {btnsWrapper}
          </Stack>
          :
          <Stack spacing={4}>
            <Grid container justifyContent={'center'} spacing={5}>
              <Grid item md={6} lg={3} display='flex' alignItems='center'>
                {phoneWrapper}
              </Grid>
              <Grid item md={6}>
                <Stack spacing={2}>
                  <h2>Selecione o layout da sua capinha</h2>
                  {layoutPicker}
                </Stack>
              </Grid>
            </Grid>
            {btnsWrapper}
          </Stack>
      }
    </div>
  );
}
