import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../app.context';
import { imageUrl, isLocal, showStepper, sleep, useStyles } from '../services';
import { StepBaseProps } from '.';
import { Button, Stack } from '@mui/material';
import { step1Complete } from '../models';

interface StepProps extends StepBaseProps {

}

const relativePath = (isLocal() ? '' : '/wp-content/reactpress/apps/minha-case/build') + '/assets/img/logos/';
const brandHeight = '60px';
const brandOpts = [
  { name: 'Apple', value: 'apple', img: 'apple-logo', width: '50px', },
  { name: 'Asus', value: 'asus', img: 'asus-logo', width: '260px', },
  { name: 'Lenovo', value: 'lenovo', img: 'lenovo-logo', width: '250px', },
  { name: 'LG', value: 'lg', img: 'lg-logo', width: '140px', },
  { name: 'Microsoft', value: 'microsoft', img: 'microsoft-logo', width: '280px', },
  { name: 'Motorola', value: 'motorola', img: 'motorola-logo', width: '80px', },
  { name: 'Nokia', value: 'nokia', img: 'nokia-logo', width: '280px', },
  { name: 'Realme', value: 'realme', img: 'realme-logo', width: '120px', },
  { name: 'Samsung', value: 'samsung', img: 'samsung-logo', width: '150px', },
  { name: 'Sony', value: 'sony', img: 'sony-logo', width: '250px', },
  { name: 'Xiaomi', value: 'xiaomi', img: 'xiaomi-logo', width: '120px', },
];

export function Step1Marca(props: StepProps) {
  const app = useContext(AppContext);
  const { product } = props;

  const [selected, setSelected] = useState(product.brand || '');

  useEffect(() => {
    const stepper = document.querySelector('#mcs-stepper');
    if (stepper) stepper.scrollIntoView({ behavior: 'smooth' })
  }, []);

  const styles = useStyles({
    root: {
    },
    btnsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse'
    },
    brandsWrapper: {
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
    },
    brandBtn: {
      padding: '30px'
    },
  })();

  return (
    <div className={styles.root}>
      <Stack spacing={4}>
        <div className={styles.brandsWrapper}>
          {brandOpts.map(x => (
            <Button className={styles.brandBtn}
              key={x.name}
              style={{ ...selected !== x.value ? { border: '1px solid rgba(0, 0, 0, 0)' } : null }}
              variant={selected === x.value ? 'contained' : 'text'}
              onClick={() => {
                if (selected === x.value) {
                  product.brand = '';
                  setSelected('');
                }
                else {
                  product.brand = x.value;
                  setSelected(x.value);
                }
                product.model = '';
                props.onProductChanged(product);

                const nextBtn = document.querySelector(`#mcs-step-1-next-btn`);
                if (nextBtn) nextBtn.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'center'
                });

              }}
            >
              <img height={brandHeight} width={x.width}
                src={imageUrl(`static/logos/${x.img}`)}
                alt={`${x.name} logo`}
              />
            </Button>
          ))}
        </div>
        <div className={styles.btnsWrapper}>
          <Button disabled={!step1Complete(product)}
            id='mcs-step-1-next-btn'
            variant='contained'
            onClick={async () => {
              if (props.onNextStep) props.onNextStep();

              await sleep(50);
              showStepper();
            }}>Próximo</Button>
        </div>
      </Stack>
    </div>
  );
}
