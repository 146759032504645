import { useContext } from 'react';

import { AppContext } from '../app.context';
import { ProductItemList } from '../components';
import { Categories } from '../contants';
import { useStyles } from '../services';

export function CapinhasPage() {
    const app = useContext(AppContext);

    const styles = useStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: '50px',
        },
        pageTitle: {
            textAlign: 'center',
            fontSize: '48px',
            color: app.theme.palette.primary.main,
        },
    })();

    return (
        <div className={styles.root}>
            <div className={styles.pageTitle}>
                <span>Capinhas</span>
            </div>
            <ProductItemList resource='products' category='29'
                type='case'
                filters={[
                    {
                        name: 'Marca',
                        filterOpts: [
                            Categories.Apple,
                            Categories.Asus,
                            Categories.LG,
                            Categories.Lenovo,
                            Categories.Microsoft,
                            Categories.Motorola,
                            Categories.Nokia,
                            Categories.Realme,
                            Categories.Samsung,
                            Categories.Sony,
                        ]
                    },
                ]}
            />
        </div>
    );
}