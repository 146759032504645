import { Button, Divider, Grid, Paper, PaperProps, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { flatten, merge } from "lodash";

import { AppContext } from "../app.context";
import { AppConfig, Categories, phonesConfig, Route } from "../contants";
import {
  clearOutCart,
  decimalString,
  deleteFileFromServer,
  getCartItems,
  getCartTotal,
  httpPost,
  removeCartItem,
  useNavigation,
  useStyles,
} from "../services";

let mpClass = "mcs-mercado-pago-";

const paperProps: PaperProps = {
  elevation: 3,
  style: { padding: "30px 30px" },
};

export function ReviewPage() {
  const app = useContext(AppContext);
  const { navigate } = useNavigation();

  const totalPrice = getCartTotal() + AppConfig.freightPrice;

  useEffect(() => {
    mpClass += String(Date.now());
    app.setLoading(true);
    /* eslint-disable */
    //@ts-ignores
    window.refreshMPClass();
    //@ts-ignore
    // var mp = new MercadoPago("APP_USR-c955c4f1-cfcf-42a7-aef0-a225e7da38ae", {
    var mp = new MercadoPago("TEST-6025a01f-fc98-4668-b135-1befed28d7e5", {
      locale: "pt-BR",
    });
    //@ts-ignore
    const renderCardPaymentBrick = async (bricksBuilder) => {
      const settings = {
        initialization: {
          amount: totalPrice,
          payer: { email: app.user?.email },
        },
        customization: { visual: { style: { theme: "flat" } } }, // 'default' |'dark' | 'bootstrap' | 'flat'
        callbacks: {
          //@ts-ignore
          onSubmit: async (cardFormData) => {
            const cartItems = Array.from(getCartItems());
            const cartItemsWithFreight = Array.from(cartItems);
            cartItemsWithFreight.push({
              name: "Frete",
              price: AppConfig.freightPrice,
            } as any);

            app.setLoading(true);
            // Create Custom Products on WooCommerce
            try {
              const promises: Promise<any>[] = [];

              cartItems.forEach((item) => {
                if (item.type !== "customized") return;
                if (item.id) return;
                promises.push(
                  (async function () {
                    const response = await httpPost(
                      `${AppConfig.wordpressUrl}/wp-json/wc/v3/products`,
                      {
                        name: `Customized Product Created by: ${
                          app.user?.name
                        } at ${new Date().toJSON()}`,
                        type: "simple",
                        description: `
                                        Marca: ${
                                          phonesConfig.find(
                                            (x) => x.brand === item.brand
                                          )?.label
                                        }<br/>
                                        Modelo: ${
                                          flatten(
                                            phonesConfig.map((x) => x.models)
                                          ).find((x) => x.value === item.model)
                                            ?.name
                                        }
                                        `,
                        regular_price: String(
                          AppConfig.customizedPrice.toFixed(2)
                        ),
                        categories: [{ id: Categories.Samsung.value }],
                        images: [{ src: item.imageUrl }],
                      },
                      { wordpressReq: true }
                    );

                    item.id = response.id;
                  })()
                );
              });

              await Promise.all(promises);
            } catch (error) {
              console.error(error);
              app.notification(
                "error",
                "Algo deu errado. Contate o administrator."
              );
              app.setLoading(false);
              return;
            }

            // Collect Payment
            try {
              cardFormData = {
                ...cardFormData,
                additional_info: {
                  items: cartItemsWithFreight.map((x, i) => ({
                    id: String(x.id),
                    category_id: "others",
                    description: "",
                    ...(x.imageUrl ? { picture_url: x.imageUrl } : undefined),
                    quantity: 1,
                    title: x.name,
                    unit_price: x.price,
                  })),
                },
              };

              await httpPost(`${AppConfig.backendUrl}/payment`, cardFormData);
            } catch (error) {
              console.error(error);
              app.notification(
                "error",
                "Falha no pagamento, por favor tente novamente"
              );
              location.reload();
              return;
            }

            try {
              const response = await httpPost(
                `${AppConfig.wordpressUrl}/wp-json/wc/v3/orders`,
                {
                  payment_method: "woo-mercado-pago-custom",
                  payment_method_title:
                    "Compras com cartões salvos ou saldo no Mercado Pago",
                  set_paid: true,
                  customer_id: Number(app.user?.id),
                  line_items: cartItems.map((x) => ({
                    product_id: x.id,
                    quantity: 1,
                  })),
                  shipping_lines: [
                    {
                      method_id: "flat_rate",
                      method_title: "Flat Rate",
                      total: String(AppConfig.freightPrice.toFixed(2)),
                    },
                  ],
                  shipping: {
                    address_1: app.address?.address1,
                    address_2: app.address?.address2,
                    city: app.address?.city,
                    state: app.address?.state,
                    postcode: app.address?.zipCode,
                    country: "Brazil",
                  },
                },
                { wordpressReq: true }
              );

              clearOutCart();
              app.setAddress();
              app.setLoading(false);
              navigate(Route.ThankYou, {});
            } catch (error) {
              console.error(error);
              app.notification(
                "error",
                "Algo deu errado. Contate o administrator."
              );
              app.setLoading(false);
            }
          },
          onReady: () => {
            app.setLoading(false);
          },
          //@ts-ignore
          onError: (error) => {
            app.setLoading(false);
          },
        },
      };

      //@ts-ignore
      const cardPaymentBrickController = await bricksBuilder.create(
        "cardPayment",
        mpClass,
        settings
      );
      /* eslint-enable */
    };
    renderCardPaymentBrick(mp.bricks());
  }, []);

  const styles = useStyles({
    root: {
    },
    pageTitle: {
      textAlign: "center",
      fontSize: "22px",
      color: app.theme.palette.primary.main,
    },
    row: {
      display: "flex",
      gap: "20px",
      alignItems: "center",
      justifyContent: "space-between",
    },
    imgWrapper: {
      height: "120px",
      width: "120px",
      textAlign: "center",
    },
    nameWrapper: {
      flexGrow: 1,
    },
    name: {
      fontSize: "18px",
    },
    description: {},
    btnWrapper: {
      display: "flex",
      gap: "15px",
    },
    price: {
      color: app.theme.palette.primary.main,
      minWidth: "100px",
    },
    totalWrapper: {
      display: "flex",
      gap: "15px",
    },
    totalLabel: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "row-reverse",
      paddingRight: "40px",
    },
    totalCel: {
      color: app.theme.palette.primary.main,
      minWidth: "100px",
    },
  })();

  const items = getCartItems();

  return (
    <div className={styles.root}>
      <Stack spacing={4}>
        <Grid container spacing={5}>
          <Grid item sm={7}>
            <Stack spacing={3}>
              <Paper {...paperProps}>
                <Stack spacing={2}>
                  <div className={styles.pageTitle}>Itens</div>

                  <Stack spacing={2}>
                    {items.map((item, i) => (
                      <div className={styles.row}>
                        <div className={styles.imgWrapper}>
                          <img src={item.imageUrl} height="100%" alt="" />
                        </div>
                        <div className={styles.nameWrapper}>
                          <Stack spacing={1}>
                            <div className={styles.name}>{item.name}</div>
                          </Stack>
                        </div>
                        <div className={styles.price}>
                          R$ {decimalString(item.price)}
                        </div>
                      </div>
                    ))}
                    <Divider />
                    <div className={styles.totalWrapper}>
                      <div
                        className={styles.totalLabel}
                        style={{ fontStyle: "italic" }}
                      >
                        Frete
                      </div>
                      <div className={styles.totalCel}>
                        R$ {AppConfig.freightPrice.toFixed(2)}
                      </div>
                    </div>
                    <div className={styles.totalWrapper}>
                      <div className={styles.totalLabel}>Total</div>
                      <div className={styles.totalCel}>
                        R$ {totalPrice.toFixed(2)}
                      </div>
                    </div>
                  </Stack>
                </Stack>
              </Paper>
              <Paper {...paperProps}>
                <Stack spacing={5}>
                  <div className={styles.pageTitle}>Endereço de entrega</div>
                  <div>
                    <div>
                      <span>{app.address?.address1}, </span>
                      <span>{app.address?.address2} - </span>
                      <span>{app.address?.zipCode}</span>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <span>{app.address?.city} - </span>
                      <span>{app.address?.state}</span>
                    </div>
                  </div>
                </Stack>
              </Paper>
            </Stack>
          </Grid>
          <Grid item sm={5}>
            <Paper {...paperProps}>
              <div id={mpClass}></div>
            </Paper>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" onClick={() => navigate(Route.Address)}>
            Voltar
          </Button>
        </div>
      </Stack>
    </div>
  );
}
