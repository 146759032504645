import { BrowserRouter as Router } from 'react-router-dom';
import { AppContextProvider } from './app.context';

import { DefaultLayout } from './layouts';

const App = () => {
  return <AppContextProvider>
    <Router basename='app'>
      <DefaultLayout />
    </Router>
  </AppContextProvider>;
};

export default App;