import { useContext, useEffect, useState } from "react";
import {
  decimalString,
  generateRandomStr,
  getCartCount,
  getCartTotal,
  imageUrl,
  isLocal,
  signIn,
  signUp,
  useNavigation,
  useStyles,
} from "../services";
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  useMediaQuery,
} from "@mui/material";
import {
  ShoppingCart as CartIcon,
  ExitToApp as SigInIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";

import { Route } from "../contants";
import { AppContext } from "../app.context";
import { useHistory } from "react-router-dom";
import json2mq from "json2mq";
import { ModalSignIn } from "./modal-sign-in";
import { red } from "@mui/material/colors";

const links = [
  { text: "MinhaCase", route: Route.Home },
  { text: "Capinhas", route: Route.Capinhas },
  { text: "Acessórios", route: Route.Acessorios },
  { text: "Contato", route: Route.Contato },
  { text: "Crie Sua Capinha!", route: Route.CrieSuaCapinha },
];

const relativePath =
  (isLocal() ? "" : "/wp-content/reactpress/apps/minha-case/build") +
  "/assets/img/logos/";

export function Header() {
  const app = useContext(AppContext);
  const { navigate } = useNavigation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  const [refresh, setRefresh] = useState(false);
  const [signInOpen, setSignInOpen] = useState(false);

  const isLarge = app.xl || app.lg;
  const isMedium = app.md || app.sm;
  const isSmall = app.xxs || app.xs;

  useEffect(() => {
    app.addNotifListener(() => setRefresh(!refresh));
    return () => app.clearOutNotifListeners();
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const styles = useStyles({
    root: {
      display: "flex",
      padding: (data: any) => data.isSmall ? '0px' : "0px 60px",
      flexDirection: "row",
    },
    imgContainer: {
      flexGrow: 10,
      "&:hover": {
        cursor: "pointer",
      },
    },
    linksContainer: {
      flexGrow: 25,
      display: "flex",
      flexDirection: "row",
      height: "fit-content",
    },
    link: {
      height: "70px",
      borderBottom: "3px solid rgba(0,0,0,0)",
      color: "#54595F",
      "&:hover": {
        color: app.theme.palette.primary.main,
        borderBottom: "3px solid " + app.theme.palette.primary.main,
        cursor: "pointer",
      },
      "&.active": {
        color: app.theme.palette.primary.main,
        borderBottom: "3px solid " + app.theme.palette.primary.main,
        cursor: "pointer",
      },
      "&:not(:last-child):after": {
        content: '""',
        height: "10px",
        borderLeft: "solid #54595F 1px",
      },
    },
    linkText: {
      margin: "36px 25px",
      display: "inline-block",
      fontSize: "15px",
      fontWeight: "400",
    },
    cartContainer: {
      padding: "20px",
      display: "flex",
      alignItems: "center",
      gap: "10px",
      "&:hover": {
        color: app.theme.palette.primary.main,
        cursor: "pointer",
      },
      "&:hover span": {
        color: app.theme.palette.primary.main,
      },
    },
    linkPrice: {
      fontSize: "14px",
      fontWeight: "400",
      color: app.theme.palette.grey[600],
    },
    cartCount: {
      backgroundColor: "red",
      color: "white",
      borderRadius: "50%",
      height: "20px",
      width: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "11px",
      marginLeft: "-16%",
      marginTop: "-24%",
    },
  })({
    isSmall
  });

  const cartCount = getCartCount();
  const cartTotal = getCartTotal().toFixed(2);

  return (
    <div className={styles.root}>
      <Grid
        container
        columns={12}
        justifyContent={isLarge ? "space-evenly" : "space-between"}
        alignItems="center"
      >
        {isSmall ? <>
          <Grid item>
            <div
              className={styles.imgContainer}
              onClick={() => navigate(Route.Home)}
            >
              <img
                width={120}
                height={120}
                src={imageUrl("static/logos/logo")}
                alt="Minha Case logo"
              />
            </div>
          </Grid>
          {/* <Grid item flexGrow={"1"} /> */}
          <Grid item paddingRight={'30px'}>
            <IconButton onClick={() => setDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor={"right"}
              PaperProps={{ style: { minWidth: "70%" } }}
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
            >
              <List>
                {app.user ? (
                  <ListItem key={app.user.name} disablePadding>
                    <ListItemButton
                      onClick={(ev) => setAnchorEl(ev.target as any)}
                    >
                      <ListItemIcon>
                        <Avatar>{app.user.name[0]}</Avatar>
                      </ListItemIcon>
                      <ListItemText primary={app.user.name} />
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <ListItem key={"Acessar"} disablePadding>
                    <ListItemButton onClick={() => setSignInOpen(true)}>
                      <ListItemIcon>
                        <SigInIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Acessar"} />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
              <Divider />
                <List>
                  <ListItem key={'cart'} disablePadding>
                    <ListItemButton onClick={() => navigate(Route.Checkout)}>
                      <ListItemIcon>
                      <Avatar sx={{ width: 24, height: 24, background: red[500] }}>
                        {cartCount}
                      </Avatar>
                      </ListItemIcon>
                      <ListItemText primary={'Carrinho'} />
                    </ListItemButton>
                  </ListItem>
                </List>
              <Divider />
              <List>
                {links.map((x, i) => (
                  <ListItem key={x.route} disablePadding>
                    <ListItemButton onClick={() => navigate(x.route)}>
                      {/* <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                          </ListItemIcon> */}
                      <ListItemText primary={x.text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </Grid>
        </>
          :
          isLarge ? <>
            <Grid item>
              <div
                className={styles.imgContainer}
                onClick={() => navigate(Route.Home)}
              >
                <img
                  width={120}
                  height={120}
                  src={imageUrl("static/logos/logo")}
                  alt="Minha Case logo"
                />
              </div>
            </Grid>
            <Grid item justifyContent="center">
              <div className={styles.linksContainer}>
                {links.map((x) => (
                  <div
                    key={x.route}
                    className={`${styles.link} ${history.location.pathname === x.route ? "active" : ""
                      }`}
                    onClick={() => navigate(x.route)}
                  >
                    <div className={styles.linkText}>{x.text}</div>
                  </div>
                ))}
              </div>
            </Grid>
            <Grid
              item
              className={styles.cartContainer}
              onClick={() => navigate(Route.Checkout)}
            >
              <span className={styles.linkPrice}> R$ {cartTotal} </span>
              <CartIcon />
              {Boolean(cartCount) && (
                <span className={styles.cartCount}>{cartCount}</span>
              )}
            </Grid>
            <Grid item>
              {app.user ? (
                <>
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItems: "center",
                      gap: "6px",
                    }}
                    onClick={(ev) => setAnchorEl(ev.target as any)}
                  >
                    <Avatar>{app.user.name[0]}</Avatar>
                    <span>{app.user.name}</span>
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  component="label"
                  onClick={() => setSignInOpen(true)}
                >
                  <SigInIcon />
                  <span style={{ marginLeft: "6px" }}>Acessar</span>
                </Button>
              )}
            </Grid>
          </>
            :
            <>
              <Grid item>
                <div
                  className={styles.imgContainer}
                  onClick={() => navigate(Route.Home)}
                >
                  <img
                    width={120}
                    height={120}
                    src={imageUrl("static/logos/logo")}
                    alt="Minha Case logo"
                  />
                </div>
              </Grid>
              <Grid item flexGrow={"1"} />
              <Grid
                item
                className={styles.cartContainer}
                onClick={() => navigate(Route.Checkout)}
              >
                <span className={styles.linkPrice}> R$ {cartTotal} </span>
                <CartIcon />
                {Boolean(cartCount) && (
                  <span className={styles.cartCount}>{cartCount}</span>
                )}
              </Grid>
              <Grid item>
                <IconButton onClick={() => setDrawerOpen(true)}>
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor={"right"}
                  PaperProps={{ style: { minWidth: "300px" } }}
                  open={drawerOpen}
                  onClose={() => setDrawerOpen(false)}
                >
                  <List>
                    {app.user ? (
                      <ListItem key={app.user.name} disablePadding>
                        <ListItemButton
                          onClick={(ev) => setAnchorEl(ev.target as any)}
                        >
                          <ListItemIcon>
                            <Avatar>{app.user.name[0]}</Avatar>
                          </ListItemIcon>
                          <ListItemText primary={app.user.name} />
                        </ListItemButton>
                      </ListItem>
                    ) : (
                      <ListItem key={"Acessar"} disablePadding>
                        <ListItemButton onClick={() => setSignInOpen(true)}>
                          <ListItemIcon>
                            <SigInIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Acessar"} />
                        </ListItemButton>
                      </ListItem>
                    )}
                  </List>
                  <Divider />
                  <List>
                    {links.map((x, i) => (
                      <ListItem key={x.route} disablePadding>
                        <ListItemButton onClick={() => navigate(x.route)}>
                          {/* <ListItemIcon>
                      {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                    </ListItemIcon> */}
                          <ListItemText primary={x.text} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Drawer>
              </Grid>
            </>}
        <Menu
          elevation={2}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          onClose={() => setAnchorEl(undefined)}
        >
          {/* <MenuItem
            onClick={() => {
              navigate(Route.Perfil);
            }}
          >
            Perfil
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              navigate(Route.MeusPedidos);
            }}
          >
            Meus Pedidos
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(undefined);
              app.setUser(undefined);
            }}
          >
            Deslogar
          </MenuItem>
        </Menu>
      </Grid>
      <ModalSignIn open={signInOpen} onClose={() => setSignInOpen(false)} />
    </div>
  );
}
//
