const consumerKey = 'ck_1f8778f47a9db5ddb5b3f17ed7939479d7b33426';
const consumerSecret = 'cs_cc631e0a0abe3be88d7a9967eb47c810ab55d490';

function hydrateUrl(url: string) {
    if (url.includes('?')) url += `&`;
    else url += `?`;
    url += `consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`;
    return url;
}

interface httpGetOptions {
    returnReqObj?: boolean,
    wordpressReq?: boolean,
}

export const httpGet = (url: string, options?: httpGetOptions): Promise<any> => {
    if (options && options.wordpressReq) url = hydrateUrl(url);

    return new Promise((res, rej) => {
        var req = new XMLHttpRequest();
        req.open("GET", url, true);
        req.setRequestHeader("Accept", "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9");
        req.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        req.onreadystatechange = function () {
            if (this.readyState === 4) {
                req.onreadystatechange = null;
                let response;
                if (this.status === 200) {
                    if (options && options.returnReqObj) {
                        res(this);
                        return;
                    }
                    else {
                        try {
                            response = JSON.parse(this.response) as any;
                        }
                        catch (error) {
                            response = this.response;
                        }
                        res(response);
                        return;
                    }
                }
                else {
                    rej(this.statusText);
                    return;
                }
            };
        }
        req.send();
    });
}

interface httpPostOptions {
    wordpressReq?: boolean,
    urlEncoded?: false,
}

export const httpPost = (url: string, body: any, options?: httpPostOptions): Promise<any> => {
    if (options && options.wordpressReq) url = hydrateUrl(url);

    return new Promise((res, rej) => {
        var req = new XMLHttpRequest();
        req.open("POST", url, true);
        req.withCredentials = false;
        req.setRequestHeader("Accept", "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9");
        if (options && options.urlEncoded) 
            req.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=utf-8");
        else
            req.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        req.onreadystatechange = function () {
            if (this.readyState === 4) {
                req.onreadystatechange = null;
                if (this.status >= 200 && this.status <= 204) res(JSON.parse(this.response || '{}') as any);
                else if (this.status === 302) res(null);
                else rej(this.statusText);
            }
        };
        if (options && options.urlEncoded) req.send(body);
        else req.send(JSON.stringify(body));
    });
}

export const httpDelete = (url: string): Promise<any> => {
    url = hydrateUrl(url);

    return new Promise((res, rej) => {
        var req = new XMLHttpRequest();
        req.open("DELETE", url, true);
        req.withCredentials = false;
        req.onreadystatechange = function () {
            if (this.readyState === 4) {
                req.onreadystatechange = null;
                if (this.status >= 200 && this.status <= 204) res(JSON.parse(this.response || '{}') as any);
                else if (this.status === 302) res(null);
                else rej(this.statusText);
            }
        };
        req.send();
    });
}