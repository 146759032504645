export interface Product {
    id?: string,
    name: string,
    type: 'case' | 'accessory' | 'customized',
    price: number,
    description?: string,
    imageUrl?: string,
    bucket?: string,
    path?: string,
    brand?: string,
    model?: string,
    layout?: ProductLayout,
    images?: ProductImagesFull
    | ProductImagesFiftyFifty
    | ProductImagesThree
    | ProductImagesFour
    | ProductImagesSix
    | ProductImagesLargeTopTwoBottom
    | ProductImagesFourTopLargeBottom
    | any;
}

export enum ProductLayout {
    Full = 1,
    FiftyFifty = 2,
    Three = 3,
    Four = 4,
    Six = 5,
    LargeTopTwoBottom = 6,
    FourTopLargeBottom = 7,
}

export interface ProductImage {
    img1: string,
}

export interface ProductImagesFull {
    bgSize: string,
    bgPosX: string,
    bgPosY: string,
    bgRotation: string,
}

export interface ProductImagesFiftyFifty {
    img1: string,
    img2: string,
}

export interface ProductImagesThree {
    img1: string,
    img2: string,
    img3: string,
}

export interface ProductImagesFour {
    img1: string,
    img2: string,
    img3: string,
    img4: string,
}

export interface ProductImagesSix {
    img1: string,
    img2: string,
    img3: string,
    img4: string,
    img5: string,
    img6: string,
}

export interface ProductImagesLargeTopTwoBottom {
    img1: string,
    img2: string,
    img3: string,
}

export interface ProductImagesFourTopLargeBottom {
    img1: string,
    img2: string,
    img3: string,
    img4: string,
    img5: string,
}



export function step1Complete(product: Product) {
    return Boolean(product.brand);
}
export function step2Complete(product: Product) {
    return step1Complete(product) && Boolean(product.model);
}
export function step3Complete(product: Product) {
    const layoutCheck = product.layout === ProductLayout.Full ?
        Boolean(product.images?.img1)
        :
        product.layout === ProductLayout.FiftyFifty ?
            Boolean(product.images?.img1) && Boolean(product.images?.img2)
            :
            product.layout === ProductLayout.Three ?
                Boolean(product.images?.img1) && Boolean(product.images?.img2) && Boolean(product.images?.img3)
                :
                product.layout === ProductLayout.Four ?
                    Boolean(product.images?.img1) && Boolean(product.images?.img2) && Boolean(product.images?.img3) && Boolean(product.images?.img4)
                    :
                    product.layout === ProductLayout.Six ?
                        Boolean(product.images?.img1) && Boolean(product.images?.img2) && Boolean(product.images?.img3) && Boolean(product.images?.img4) && Boolean(product.images?.img5) && Boolean(product.images?.img6)
                        :
                        product.layout === ProductLayout.LargeTopTwoBottom ?
                            Boolean(product.images?.img1) && Boolean(product.images?.img2) && Boolean(product.images?.img3)
                            :
                            product.layout === ProductLayout.FourTopLargeBottom ?
                                Boolean(product.images?.img1) && Boolean(product.images?.img2) && Boolean(product.images?.img3) && Boolean(product.images?.img4) && Boolean(product.images?.img5)
                                :
                                false;

    return step2Complete(product) && Boolean(product.layout) && layoutCheck;
}
