import { imageUrl, isLocal, useStyles } from '../services';

const relativePath = (isLocal() ? '' : '/wp-content/reactpress/apps/minha-case/build') + '/assets/img/logos/';

export function WhatsApp() {

  const styles = useStyles({
    root: {
      backgroundColor: 'white',
      borderRadius: '50%',
      width: '80px',
      height: '80px',
      position: 'fixed',
      bottom: '50px',
      right: '50px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })();

  return (
    <div className={styles.root}
      onClick={() => {
        window.open(`https://api.whatsapp.com/message/6RVP7PCJXPGTC1?autoload=1&app_absent=0`, '#blank')    
      }}
    >
      <img width={80} height={80}
        src={imageUrl('static/logos/whatsapp')}
        alt="WhatsApp logo"
      />
    </div>
  );
}
